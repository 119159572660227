import React from 'react';
import { Link } from 'react-router-dom';
import './PaginaPrincipalCliente.css';

const PaginaPrincipalCliente = () => {
  return (
    <div className="centrado">
      <h1>BIENVENIDO A AMIMASCOTA</h1>
      <img src="LogoMascota.png" alt="Logo de AmiMascota" />
      <br></br>
      {/* Añadir el enlace para controlar IoT */}
      <Link to="/cliente/controlar-iot">Controlar IoT</Link>
    </div>
  );
};

export default PaginaPrincipalCliente;