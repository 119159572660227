import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff } from '@fortawesome/free-solid-svg-icons';

const WaterDispenserControl = () => {
  const [isOn, setIsOn] = useState(false);

  // Esta función envía un comando TOGGLE al servidor para cambiar el estado del dispensador de agua.
  const toggleDispenser = () => {
    fetch('https://backend-render-qavo.onrender.com/api/iot/activarRele', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ mensaje: 'TOGGLE' }),
    })
      .then(response => response.json())
      .then(data => {
        console.log('Respuesta del servidor:', data);
        // Simula el estado de encendido por unos segundos antes de regresar a apagado
        setIsOn(true); // Primero enciende
        // Después de 3 segundos, vuelve a llamar a la API para apagarlo o solo cambiar el estado si se asume que el servidor cambia el estado automáticamente.
        setTimeout(() => {
          // Aquí puedes enviar otro comando TOGGLE o simplemente cambiar el estado de isOn.
          // Si tu dispositivo se apaga automáticamente, simplemente cambia el estado a false.
          setIsOn(false);
        }, 3000); // 3000 milisegundos = 3 segundos
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const buttonStyles = {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'none',
    border: '2px solid',
    borderColor: isOn ? '#4CAF50' : '#457B9D',
    borderRadius: '50%',
    outline: 'none',
    cursor: 'pointer',
    fontSize: '4rem',
    color: isOn ? '#4CAF50' : '#457B9D',
    width: '100px',
    height: '100px',
    padding: '0',
    transition: 'all 0.3s ease',
    boxShadow: '0 2px 10px rgba(0,0,0,0.2)',
    transform: 'scale(1)',
  };

  return (
    <div style={{ padding: '20px', textAlign: 'center' }}>
      <h2 style={{ fontFamily: 'Arial, sans-serif', color: '#333', fontSize: '18px' }}>Agua</h2>
      <p style={{ fontFamily: 'Arial, sans-serif', color: '#555', margin: '10px 0' }}>
        <strong>{isOn ? 'Encendido' : 'Apagado'}</strong>
      </p>
      <button 
        onClick={toggleDispenser} 
        style={buttonStyles}
      >
        <FontAwesomeIcon icon={faPowerOff} />
      </button>
    </div>
  );
};

export default WaterDispenserControl;
