import React from 'react';
import FoodDispenserControl from './FoodDispenserControl';

const FoodDispenserControlContainer = () => {
  return (
    <div style={{
      maxWidth: '600px',
      margin: '40px auto',
      padding: '20px',
      textAlign: 'center',
      borderRadius: '15px',
    }}>
      <FoodDispenserControl />
    </div>
  );
};

export default FoodDispenserControlContainer;
