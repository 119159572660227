import React from 'react';
import './PaginaPrincipalCliente.css';

const PaginaPrincipalCliente = () => {
  return (
    <div className="centrado">
      <br></br><br></br><br></br><br></br>
      <h1>BIENVENIDO A AMIMASCOTA</h1>
      <img src="LogoMascota.png" alt="Descripción de la imagen" />
      <br></br><br></br><br></br><br></br>
    </div>
  );
};

export default PaginaPrincipalCliente;