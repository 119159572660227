import React from 'react';
import { useLocation } from 'react-router-dom';
import EncabezadoPublico from '../Compartidos/EncabezadoPublico';
import EncabezadoAdministrativo from '../Compartidos/EncabezadoAdministrativo';
import EncabezadoCliente from '../Compartidos/EncabezadoCliente';
import PieDePaginaCliente from '../Compartidos/PieDePaginaCliente';
import PieDePagina from '../Compartidos/PieDePagina';
import PieDePaginaAdmin from '../Compartidos/PieDePaginaAdmin';




const LayoutConEncabezado = ({ children }) => {
  const location = useLocation();
  let encabezado;
  let pieDePagina;

 
  if (location.pathname.startsWith('/admin')) {
    encabezado = <EncabezadoAdministrativo />;
    pieDePagina = <PieDePaginaAdmin />;
  } else if (location.pathname.startsWith('/cliente')) {
    encabezado = <EncabezadoCliente />;
    pieDePagina = <PieDePaginaCliente />;
  } else {
    encabezado = <EncabezadoPublico />;
    pieDePagina = <PieDePagina />;
  }

  return (
    <>
      {encabezado}
      <div>
        {children}
        {pieDePagina}
      </div>
    </>
  );
};

export default LayoutConEncabezado;
