import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from "react-router-dom";
import { ArrowLeftOutlined } from '@ant-design/icons';
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import "./Styles_Shared/Registro.css";

function PreguntaSecreta() {
  const navigate = useNavigate();
  const [respuesta, setRespuesta] = useState('');
  const [pregunta, setPregunta] = useState('');
  const [usuario, setUsuario] = useState([]);
  const [mostrar, setMostrar] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { id } = useParams();

  useEffect(() => {
    // Obtener usuarios
    axios.get(`https://backend-render-qavo.onrender.com/api/usuarios`)
      .then(response => {
        if (response.status === 200) {
          setUsuario(response.data);
        } else {
          setErrorMessage('Usuario no encontrado');
        }
      })
      .catch(error => {
        console.error('Error al obtener el usuario:', error);
        setErrorMessage('Error al obtener el usuario. Por favor, inténtalo de nuevo más tarde.');
      });
  }, []);

  useEffect(() => {
    // Obtener preguntas
    axios.get(`https://backend-render-qavo.onrender.com/api/registro`)
      .then(response => {
        if (response.status === 200) {
          setPregunta(response.data);
        } else {
          setErrorMessage('Pregunta no encontrada');
        }
      })
      .catch(error => {
        console.error('Error al obtener la pregunta:', error);
        setErrorMessage('Error al obtener la pregunta. Por favor, inténtalo de nuevo más tarde.');
      });
  }, []);

  
  useEffect(() => {
    const usuarioEncontrado = usuario.find(usuario => usuario._id === id);
  
    if (usuarioEncontrado && usuarioEncontrado.datos_cliente) {
      const idPregunta = usuarioEncontrado.datos_cliente.pregunta;
      // Validar que pregunta sea un array antes de usar find
      if (Array.isArray(pregunta)) {
        const preguntaEncontrada = pregunta.find(pregunta => pregunta._id === idPregunta);
        setMostrar(preguntaEncontrada ? preguntaEncontrada.pregunta : '');
      } else {
        setErrorMessage('Error: la lista de preguntas no es válida');
      }
    }
  }, [id, pregunta, usuario]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const comparar = usuario.find(usuario => usuario._id === id);
    if (comparar.respuesta === respuesta) {
      navigate(`/actualizarPass/${id}`);
    } else {
      setErrorMessage('Respuesta incorrecta');
    }
  }

  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="estiloContenedor-1">
              <ArrowLeftOutlined className="back-icones" onClick={goBack} />
      <Link to="/">
      <br></br>
      <div className="incon_house-c">&#127968;</div>
      </Link>
      <h1 className="estiloTitulo">Pregunta de recuperacion</h1>
      <form onSubmit={handleSubmit} autoComplete='of'>
      <div className="estiloCampo">  
      <label htmlFor="txtp" className="estiloEtiqueta">
            &#128100; Pregunta:
          </label>    
        <select className="estiloInput" value={mostrar} onChange={() => {}}>
          <option value={mostrar}>{mostrar}</option> 
        </select>
        <br />
        </div>
        <div className="estiloCampo">  
        <label htmlFor="txtr" className="estiloEtiqueta">
            &#128100; Respuesta:
          </label>    
        <input className="estiloInput"
          type="text" 
          value={respuesta} 
          onChange={(e) => setRespuesta(e.target.value)} 
          placeholder="Ingrese su respuesta" 
          required
        />
        </div>
        <br />
        <button  className="estiloBoton" type="submit">Validar Respuesta</button>
      </form>
      {errorMessage && <p>{errorMessage}</p>}
    </div>
    </>
  )
}

export default PreguntaSecreta;
