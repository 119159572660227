import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import { ArrowLeftOutlined } from '@ant-design/icons';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const DetallesProducto = () => {
    const { productoId } = useParams();
    const [producto, setProducto] = useState(null);
    const navigate = useNavigate(); // Modificado: Usar useNavigate para obtener el objeto de navegación

    useEffect(() => {
        const fetchProducto = () => {
            fetch(`https://backend-render-qavo.onrender.com/api/productos/detalles/${productoId}`)
                .then(response => response.json())
                .then(data => setProducto(data))
                .catch(error => console.error('Error fetching producto:', error));
        };

        // Fetch inicial
        fetchProducto();

        // Establecer intervalo para actualizar cada 5 segundos
        const interval = setInterval(fetchProducto, 5000);

        // Limpieza al desmontar el componente
        return () => clearInterval(interval);
    }, [productoId]);

    if (!producto) return <div className="cargando">Cargando...</div>;

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <>
            <style>
                {`
                                      .producto-detalle-container {
                                        display: flex;
                                        flex-direction: row;
                                        gap: 40px;
                                        padding: 20px; /* Reducimos el padding */
                                        background-color: #ffffff;
                                        border-radius: 12px;
                                        margin: 20px auto;
                                        max-width: 900px; /* Reducimos el ancho máximo */
                                        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                                    }
                
                                    .producto-imagen {
                                        flex: 1; /* Ajustamos el tamaño del carrusel */
                                        max-width: 50%; /* Ajustamos el ancho máximo */
                                    }
                
                                    .producto-imagen img {
                                        width: 100%; /* Ajustamos el ancho de las imágenes */
                                        height: auto;
                                        border-radius: 8px;
                                    }
                
                                    .producto-info {
                                        flex: 1;
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: center;
                                    }
                
                                    h1 {
                                        font-family: 'Merriweather', serif;
                                        font-size: 2.5rem;
                                        color: #333;
                                        margin-bottom: 20px;
                                    }
                
                                    .descripcion, .precio, p {
                                        font-family: 'Open Sans', sans-serif;
                                        font-size: 1rem;
                                        line-height: 1.6;
                                        color: #555;
                                        margin-bottom: 15px;
                                    }
                
                                    .descripcion {
                                        background-color: rgba(255, 235, 230, 0.5);
                                        padding: 10px;
                                        border-left: 4px solid #ff8c00;
                                        font-size: 1.1rem;
                                    }
                
                                    .precio {
                                        font-size: 1.4rem;
                                        font-weight: bold;
                                        color: #333;
                                    }
                
                                    .icono-retroceso {
                                        cursor: pointer;
                                        font-size: 24px;
                                        color: #1890ff; // Color de Ant Design para el icono
                                        margin-bottom: 10px;
                                    }
                `}
            </style>
            <br></br>
            <ArrowLeftOutlined className="icono-retroceso" onClick={() => navigate(-1)} />
            <div className="producto-detalle-container">
               {/* Usar navigate con un argumento negativo para retroceder */}
                <div className="producto-imagen">
                    <Slider {...settings}>
                        {producto.fotos.map((foto, index) => (
                            <div key={index}>
                                <img src={foto.url} alt={`Foto ${index + 1} de ${producto.nombre}`} />
                            </div>
                        ))}
                    </Slider>
                </div>
                <div className="producto-info">
                    <h1>{producto.nombre}</h1>
                    <p className="descripcion">{producto.descripcion}</p>
                    <p className="precio">Precio: ${producto.precio}</p>
                    <p>Categoría: {producto.categoria}</p>
                    <p>Color: {producto.colores.map(color => color.nombre).join(', ')}</p>
                </div>
            </div>
        </>
    );
};

export default DetallesProducto;
