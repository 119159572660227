import React, { useRef, useEffect, useState } from 'react';

const WaterTank = ({ level, maxLevel }) => {
  const canvasRef = useRef(null);
  const [currentLevel, setCurrentLevel] = useState((level / maxLevel) * 100);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentLevel((prevLevel) => {
        const targetLevel = (level / maxLevel) * 100; // Calcula el nivel objetivo como porcentaje del máximo
        const delta = targetLevel - prevLevel;
        if (Math.abs(delta) < 0.5) {
          clearInterval(intervalId);
          return targetLevel;
        }
        return prevLevel + delta * 0.05; // Ajusta la velocidad de transición aquí
      });
    }, 16); // Aproximadamente 60 fps

    return () => clearInterval(intervalId);
  }, [level, maxLevel]);

  const drawWaves = (ctx, timestamp) => {
    const width = ctx.canvas.width;
    const height = ctx.canvas.height;
    ctx.clearRect(0, 0, width, height); // Limpiar el canvas

    // Configuración de las olas
    ctx.fillStyle = '#457B9D';
    const waveAmplitude = 7; // Altura de las olas
    const waveFrequency = 0.03; // Frecuencia de las olas
    const waveSpeed = 0.003; // Velocidad de las olas

    // Dibujar olas
    ctx.beginPath();
    for (let x = 0; x < width; x++) {
      const y = Math.sin(x * waveFrequency + timestamp * waveSpeed) * waveAmplitude + (height * (1 - currentLevel / 100));
      ctx.lineTo(x, y);
    }
    ctx.lineTo(width, height);
    ctx.lineTo(0, height);
    ctx.closePath();
    ctx.fill();
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    let frameId;

    const render = (timestamp) => {
      drawWaves(context, timestamp);
      frameId = requestAnimationFrame(render);
    };

    frameId = requestAnimationFrame(render);

    return () => cancelAnimationFrame(frameId);
  }, [currentLevel]);

  return (
    <div style={{ width: '100px', height: '200px', position: 'relative', border: '5px solid #457B9D', borderRadius: '15px', overflow: 'hidden' }}>
      <div style={{ position: 'absolute', width: 'calc(100% - 10px)', height: 'calc(100% - 10px)', backgroundColor: '#A8DADC', borderRadius: '10px', top: '5px', left: '5px' }} />
      <canvas ref={canvasRef} width="100" height="200"  style={{ position: 'absolute', top: '0', left: '0' }}  />
      <div style={{ position: 'absolute', top: '5px', left: '50%', transform: 'translateX(-50%)', color: 'white', fontWeight: 'bold', fontSize: 'smaller' }}>
        {Math.round(currentLevel)}%
      </div>
    </div>
  );
};
export default WaterTank;
