import React from 'react';

const DetallesProductoCliente = () => {
  return (
    <div>
      <h1>DetallesProducto Cliente</h1>
      
    </div>
  );
};

export default DetallesProductoCliente;