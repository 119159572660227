import React, { useState, useEffect} from 'react';
import axios from 'axios';
import { useNavigate, useParams } from "react-router-dom";
import { ArrowLeftOutlined } from '@ant-design/icons';
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import "./Styles_Shared/Registro.css";



 
function ValidarCodigo() {
  const navigate = useNavigate();
  const [Token, setToken] = useState('');
  const { menu } = useParams();
  const [errorMessage, setErrorMessage] = useState('');
  const [usuarios, setUsuarios] = useState([]);
    

  //consulta de los usuarios
  useEffect(() => {
    axios.get(`https://backend-render-qavo.onrender.com/api/usuarios`)
      .then(response => {
        if (response.status === 200) {
          setUsuarios(response.data);
          setErrorMessage('');
          console.log('Se obtuvieron los usuarios correctamente.');
        } else {
          setErrorMessage('Error al obtener los usuarios desde la base de datos.');
          console.error('Error al obtener los usuarios:', response);
        }
      })
      .catch(error => {
        console.error('Error al obtener los usuarios:', error);
        setErrorMessage('Error al obtener los usuarios desde la base de datos. Por favor, inténtalo de nuevo más tarde.');
      });
  }, []);


  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const usuarioEncontrado = usuarios.find(usuario => usuario.Token === Token);
    const id = usuarioEncontrado ? usuarioEncontrado._id : '';
    const envPregunta= usuarioEncontrado? usuarioEncontrado.datos_cliente.pregunta: '';
    const foundToken = usuarioEncontrado ? usuarioEncontrado.Token : '';
    const expira = usuarioEncontrado ? usuarioEncontrado.expira : '';
    
    if (usuarioEncontrado) {
      setErrorMessage('El token coincide');
      const updatedData = {
        Token: " ",
        expira: " "
      };
  
      try {
        await axios.patch(`https://backend-render-qavo.onrender.com/api/usuarios/${usuarioEncontrado._id}`, updatedData);
        
        setErrorMessage('El Token se actualizo')
        const menuNumero= parseInt(menu);
        console.log(usuarioEncontrado.datos_cliente.pregunta);
        if(menuNumero===1){
         navigate(`/preguntaSecreta/${id}`);
        }else if(menuNumero===2){
          navigate(`/actualizarPass/${id}`);
        }
       
       
 
        
         
        



       
      } catch (error) {
        console.error('Error al guardar en la base de datos:', error);
        setErrorMessage('Error al guardar en la base de datos. Por favor, inténtalo de nuevo más tarde.');
      }
    } else {
      setErrorMessage('El Token no existe en la base de datos.');
    }
  };
  
  const goBack = () => {
    navigate(-1);
  };

  
  return (
    <>
      <div className="estiloContenedor-1">
              <ArrowLeftOutlined className="back-icones" onClick={goBack} />
      <Link to="/">
      <br></br>
      <div className="incon_house-c">&#127968;</div>
      </Link>
      <h1 className="estiloTitulo">Codigo de validacion</h1>
      
        <form onSubmit={handleSubmit} autoComplete="of">
        <div className="estiloCampo"> 
        <label htmlFor="txtp" className="estiloEtiqueta">
            &#128100; Codigo: 
          </label>     
          <input className="estiloInput"
          type="text" value={Token} onChange={(e) => setToken(e.target.value)}  required/>
          </div>
          <br></br>
        <button  className="estiloBoton" type="submit">Validar Codigo</button>
        </form>
      
      {errorMessage && <p>{errorMessage}</p>}
     
    </div>
    </>
  );
}



export default ValidarCodigo;
