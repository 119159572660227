import React, { useState, useEffect } from 'react';
import axios from 'axios';
import FoodBowl from './FoodBowl';

const FoodBowlContainer = ({ idDispositivo }) => {
  const [foodLevel, setFoodLevel] = useState(0);
  const maxLevel = 100; // Suponiendo que el plato puede contener hasta un nivel 100 de comida

  useEffect(() => {
    const dispensadoraId = idDispositivo; // Usando el idDispositivo recibido como propiedad

    const interval = setInterval(() => {
      axios.get(`https://backend-render-qavo.onrender.com/api/iot/topic4?id=${dispensadoraId}`)
        .then(response => {
          const { valor } = response.data;
          console.log('Valor recibido del servidor para el nivel de comida:', valor);
          // Ajusta el valor recibido al rango de 0 a 100
          const adjustedValue = Math.min((valor / 100) * 100, 100);
          setFoodLevel(adjustedValue);

          // Actualiza solo el campo platos.comida en la dispensadora
          axios.put(`https://backend-render-qavo.onrender.com/api/dispositivo/${dispensadoraId}`, {
            "platos.comida": adjustedValue
          })
            .then(response => {
              console.log('Dispensadora actualizada en el servidor:', response.data);
            })
            .catch(error => {
              console.error('Error al actualizar la dispensadora en el servidor:', error);
            });

        })
        .catch(error => {
          console.error('Error al obtener el nivel de comida:', error);
        });
    }, 500); // Realiza la solicitud cada 5 segundos

    return () => clearInterval(interval);
  }, [idDispositivo]); // Asegurándose de que el efecto se vuelva a ejecutar si idDispositivo cambia

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '20px',
      textAlign: 'center',
      maxWidth: '400px',
      margin: '60px auto', // Se ajusta el margen superior para bajar el contenido
    },
    title: {
      color: '#333',
      marginBottom: '20px',
      fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
      fontWeight: '300',
      fontSize: '18px', // Reducido el tamaño de la fuente para hacer las letras más "cortas".

    },
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Nivel de Comida</h2>
      <FoodBowl level={foodLevel} maxLevel={maxLevel} />
    </div>
  );
};

export default FoodBowlContainer;
