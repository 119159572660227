import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import './PoliticasPrivacidadCliente.css';

const PoliticasPrivacidadCliente = () => {
    const [politicasPrivacidad, setPoliticasPrivacidad] = useState([]);
    const navigate = useNavigate(); // Obtener la función navigate

    useEffect(() => {
        fetch('https://backend-render-qavo.onrender.com/api/politicasPrivacidad')
            .then(response => response.json())
            .then(data => setPoliticasPrivacidad(data))
            .catch(error => console.error('Error fetching políticas de privacidad:', error));
    }, []);

    // Función para retroceder en la navegación
    const goBack = () => {
        navigate(-1);
    };

    return (
        <div className="container">
            {/* Agregar el icono de retroceso con la función goBack */}
            <ArrowLeftOutlined className="back-icon" onClick={goBack} />
            <h2 className="header">Politicas</h2>
            {politicasPrivacidad.map((politica, index) => (
                <div key={index} className="policy-box">
                    <h3 className="policy-title">{politica.titulo}</h3>
                    <p className="policy-description">{politica.descripcion}</p>
                </div>
            ))}
        </div>
    );
};

export default PoliticasPrivacidadCliente;
