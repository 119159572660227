import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';

const estilos = {
  contenedor: {
    maxWidth: '100%',
    padding: '20px 40px',
  },
  tabla: {
    width: '100%',
    borderCollapse: 'collapse',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    margin: '20px 0',
    fontFamily: '"Segoe UI", Arial, sans-serif',
  },
  cabecera: {
    backgroundColor: '#800080',
    color: 'white',
    padding: '12px 15px',
  },
  celda: {
    border: '1px solid #ddd',
    padding: '10px 15px',
    textAlign: 'left',
    backgroundColor: 'white',
  },
  botonEditar: {
    cursor: 'pointer',
    backgroundColor: '#008000',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    padding: '5px 10px',
    display: 'block',
    margin: '20px auto',
    width: 'fit-content',
  },
};

function CrudPreguntas() {
  const navigate = useNavigate();
  const [preguntas, setPreguntas] = useState([]); // Directamente maneja un arreglo de preguntas
  const [editar, setEditar] = useState(false);

  // Carga inicial de preguntas frecuentes
  useEffect(() => {
    cargarPreguntasFrecuentes();
  }, []);

  // Simplificamos el manejo de la carga de datos para trabajar directamente con un arreglo
  const cargarPreguntasFrecuentes = async () => {
    try {
      const response = await axios.get('https://backend-render-qavo.onrender.com/api/faqs');
      setPreguntas(Object.values(response.data)); // Convertimos el objeto en un arreglo de valores
    } catch (error) {
      console.error("Error al cargar Preguntas Frecuentes", error);
    }
  };

  // Regresar a la página anterior
  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <ArrowLeftOutlined onClick={goBack} style={{ fontSize: '24px', cursor: 'pointer' }} />
      <div style={estilos.contenedor}>
        <h2 style={estilos.titulo}>Preguntas Frecuentes</h2>
        {!editar ? (
          <div>
            <table style={estilos.tabla}>
              <thead>
                <tr>
                  <th style={estilos.cabecera}>Pregunta</th>
                  <th style={estilos.cabecera}>Respuesta</th>
                </tr>
              </thead>
              <tbody>
                {preguntas.map((faq, index) => (
                  <tr key={faq._id}>
                    <td style={estilos.celda}>{faq.preguntas}</td>
                    <td style={estilos.celda}>{faq.respuesta}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button style={estilos.botonEditar} onClick={() => setEditar(true)}>Editar</button>
          </div>
        ) : (
          <div>
            {/* Aquí va tu formulario de edición */}
          </div>
        )}
      </div>
    </>
  );
}

export default CrudPreguntas;