import React from 'react';
import { Layout, Menu } from 'antd';
import {
  UserOutlined,
  ShoppingOutlined,
  InfoCircleOutlined,
  QuestionCircleOutlined,
  LogoutOutlined,
  TeamOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import logo from './../../imagenes/LogoMascota.png';
import { useNavigate } from 'react-router-dom';

const { Header } = Layout;
const { SubMenu } = Menu;

const EncabezadoAdministrativo = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    console.log('Cerrando sesión...');
    // Aquí tu lógica para cerrar sesión
  };

  const handleMenuClick = (e) => {
    switch(e.key) {
      case "1":
        navigate('/admin/usuarios');
        break;
      case "2":
        navigate('/admin/insertarUsuario');
        break;
      case "3":
        navigate('/admin/productos');
        break;
      case "4":
        navigate('/admin/insertarProductos');
        break;
      case "5":
        navigate('/admin/informacion/lista-quienes-somos');
        break;
      
      case "6":
        navigate('/admin/informacion/mision-vision-lista');
        break;
      
      case "7":
        navigate('/admin/informacion/politicas-privacidad-lista');
        break;
      case "8":
        navigate('/admin/preguntas-frecuentes');
        break;
      case "9":
        navigate('/admin/asignar-IOT');
        break;
        case "10":
        navigate('/admin/agregarIot');
        break;
      case "11":
        localStorage.removeItem('usuario');
        navigate('/');
        break;
      default:
        console.log("No se reconoce la acción del menú");
    }
  };

  return (
    <Layout className="layout">
      <Header style={{ padding: 45, display: 'flex', alignItems: 'center', backgroundColor: '#FFA500' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
        <img src={logo} alt="Logo" style={{ height: '90px', marginRight: '10px' }} />
          <span style={{ color: 'white', fontSize: '30px', fontWeight: 'bold' }}>AmiMascota</span>
         
        </div>

        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']} style={{ lineHeight: '90px', fontSize: '18px', backgroundColor: '#FFA500', marginLeft: 'auto' }} onClick={handleMenuClick}>
          <SubMenu key="sub0" icon={<UserOutlined />} title="Usuarios">
            <Menu.Item key="1">Lista Usuarios</Menu.Item>
            <Menu.Item key="2">Insertar usuarios</Menu.Item>
          </SubMenu>
          <SubMenu key="sub1" icon={<ShoppingOutlined />} title="Productos">
            <Menu.Item key="3">Lista Productos</Menu.Item>
            <Menu.Item key="4">Insertar Producto</Menu.Item>
          </SubMenu>
          <SubMenu key="sub2" icon={<InfoCircleOutlined />} title="Información Empresa">
            <SubMenu key="sub2-1" icon={<TeamOutlined />} title="Quiénes Somos">
              <Menu.Item key="5">Lista Quiénes Somos</Menu.Item>
              
            </SubMenu>
            <SubMenu key="sub2-2" icon={<EyeOutlined />} title="Misión y Visión">
              <Menu.Item key="6">Lista Misión y Visión</Menu.Item>
              
            </SubMenu>
            <SubMenu key="sub2-3" icon={<EyeOutlined />} title="Políticas y Privacidad">
              <Menu.Item key="7">Lista Políticas y Privacidad</Menu.Item>
              
            </SubMenu>
          </SubMenu>
          <Menu.Item key="8" icon={<QuestionCircleOutlined />}>Preguntas Frecuentes</Menu.Item>
          <SubMenu key="sub2-4" icon={<EyeOutlined />} title="IOT">
          <Menu.Item key="9" icon={<QuestionCircleOutlined />}>Asignar IOT</Menu.Item>
          <Menu.Item key="10" icon={<QuestionCircleOutlined />}>Agregar IOT</Menu.Item>
          </SubMenu>


          <Menu.Item key="11" icon={<LogoutOutlined />} onClick={handleLogout}>Cerrar Sesión</Menu.Item>
        </Menu>
      </Header>
    </Layout>
  );
};

export default EncabezadoAdministrativo;
