import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Importaciones de tus contenedores de widgets
import WaterTankContainer from '../Wiggets/tanqueAgua';
import FoodTankContainer from '../Wiggets/FoodTankContainer';
import WaterBowlContainer from '../Wiggets/WaterBowlContainer';
import FoodBowlContainer from '../Wiggets/FoodBowlContainer';
import DispenserControlContainer from '../Wiggets/DispenserControlContainer';
import FoodDispenserControlContainer from '../Wiggets/FoodDispenserControlContainer';

const WidgetCard = ({ title, children }) => (
  <div style={{
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '10px',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 6px 20px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#ffffff',
    border: 'none',
    transition: 'transform 0.3s ease',
    maxWidth: '500px',
  }}>
    <h4 style={{ margin: '10px 0', fontSize: '1.4rem', color: '#2c3e50', fontWeight: 'bold' }}>{title}</h4>
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '20px',
    }}>
      {children}
    </div>
  </div>
);

const IoTCliente = () => {
  const navigate = useNavigate();
  const [usuario, setUsuario] = useState(JSON.parse(localStorage.getItem('usuario')) || {});

  useEffect(() => {
    document.body.style.background = 'linear-gradient(180deg, #ff8800, #ff6600)';
    return () => {
      document.body.style.background = '';
    };
  }, []);

  const dispositivos = usuario.iotDev || [];

  return (
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', maxWidth: '12000px', padding: '20px', borderRadius: '10px', background: 'rgba(255, 255, 255, 0.8)', boxShadow: '0 8px 25px rgba(0, 0, 0, 0.1)' }}>
      {dispositivos.length > 0 ? (
        <>
          <WidgetCard title="Tanques">
            <WaterTankContainer />
            <FoodTankContainer />
          </WidgetCard>
          <WidgetCard title="Recipientes">
            <WaterBowlContainer />
            <FoodBowlContainer />
          </WidgetCard>
          <WidgetCard title="Control Dispensador">
            <DispenserControlContainer />
            <FoodDispenserControlContainer />
          </WidgetCard>
        </>
      ) : (
        <div style={{ textAlign: 'center', width: '100%' }}>
          <h2>No tienes un dispositivo asignado.</h2>
        </div>
      )}
    </div>
  );
};

export default IoTCliente;
