import React, { useRef, useEffect, useState } from 'react';

const FoodBowl = ({ level, maxLevel }) => {
  const canvasRef = useRef(null);
  const [currentLevel, setCurrentLevel] = useState((level / maxLevel) * 100);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentLevel((prevLevel) => {
        const targetLevel = (level / maxLevel) * 100;
        const delta = targetLevel - prevLevel;
        if (Math.abs(delta) < 0.5) {
          clearInterval(intervalId);
          return targetLevel;
        }
        return prevLevel + delta * 0.05;
      });
    }, 16);

    return () => clearInterval(intervalId);
  }, [level, maxLevel]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    drawFood(context);
  }, [currentLevel]);

  const drawFood = (ctx) => {
    const width = ctx.canvas.width;
    const height = ctx.canvas.height;
    const foodHeight = height * (currentLevel / 100);
    ctx.clearRect(0, 0, width, height);
    
    // Create a linear gradient
    const gradient = ctx.createLinearGradient(0, height, 0, height - foodHeight);
    // Color stops
    if (currentLevel > 20) {
      gradient.addColorStop(0, '#F4A261'); // Normal color (orange)
      gradient.addColorStop(1, '#E9C46A'); // Transition color
    } else {
      gradient.addColorStop(0, '#E76F51'); // Color when almost empty (red)
      gradient.addColorStop(1, '#F4A261'); // Transition color towards almost empty
    }

    ctx.fillStyle = gradient;
    ctx.fillRect(0, height - foodHeight, width, foodHeight); // Draw food level with gradient
  };

  return (
    <div style={{ width: '150px', height: '150px', position: 'relative', border: '6px solid #E76F51', borderRadius: '50%', overflow: 'hidden' }}>
      <canvas ref={canvasRef} width="150" height="150" style={{ position: 'absolute', top: '0', left: '0' }} />
      <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: '#E76F51', fontWeight: 'bold' }}>
        {Math.round(currentLevel)}%
      </div>
    </div>
  );
};

export default FoodBowl;
