import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';


// Importaciones de tus contenedores de widgets...
import WaterTankContainer from '../Wiggets/tanqueAgua';
import FoodTankContainer from '../Wiggets/FoodTankContainer';
import WaterBowlContainer from '../Wiggets/WaterBowlContainer';
import FoodBowlContainer from '../Wiggets/FoodBowlContainer';
import DispenserControlContainer from '../Wiggets/DispenserControlContainer';
import FoodDispenserControlContainer from '../Wiggets/FoodDispenserControlContainer';

const WidgetCard = ({ title, children }) => (
  <div style={{
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '10px',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 6px 20px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#ffffff',
    border: 'none',
    transition: 'transform 0.3s ease',
    maxWidth: '500px',
  }}>
    <h4 style={{ margin: '10px 0', fontSize: '1.4rem', color: '#2c3e50', fontWeight: 'bold' }}>{title}</h4>
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '20px',
    }}>
      {children}
    </div>
  </div>
);

const Dispositivo = () => {
  const navigate = useNavigate();
  const {id} = useParams();

  // Ajustando el fondo del cuerpo al montar el componente
  useEffect(() => {
    document.body.style.background = 'linear-gradient(180deg, #ff8800, #ff6600)';

    // Limpiar el estilo al desmontar el componente
    return () => {
      document.body.style.background = '';
    };
  }, []);

  return (
    <div style={{ 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center', 
      padding: '20px',
    }}>
      <h2 style={{ 
        marginBottom: '20px', 
        color: 'white', 
        textShadow: '1px 1px 5px rgba(0, 0, 0, 0.5)',
        fontWeight: 'bold'
      }}>Control IOT</h2>
      <div style={{ 
        display: 'flex', 
        flexDirection: 'row', 
        justifyContent: 'space-between', 
        width: '100%', 
        maxWidth: '12000px', // Asegurándonos de que el valor de maxWidth es correcto
        padding: '20px',
        borderRadius: '10px',
        background: 'rgba(255, 255, 255, 0.8)',
        boxShadow: '0 8px 25px rgba(0, 0, 0, 0.1)',
      }}>
        <WidgetCard title="Tanques">
          <WaterTankContainer idDispositivo={id} />
          <FoodTankContainer idDispositivo={id} />
        </WidgetCard>
        
        <WidgetCard title="Recipientes">
          <WaterBowlContainer idDispositivo={id} />
          <FoodBowlContainer  idDispositivo={id}/>
        </WidgetCard>
        
        <WidgetCard title="Control Dispensador">
          <DispenserControlContainer idDispositivo={id} />
          <FoodDispenserControlContainer idDispositivo={id}/>
        </WidgetCard>
      </div>
    </div>
  );
};

export default Dispositivo;
