import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';

const estilos = {
    contenedor: {
        maxWidth: '100%',
        padding: '20px 40px',
    },
    tabla: {
        width: '100%',
        borderCollapse: 'collapse',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        margin: '20px 0',
        fontFamily: '"Segoe UI", Arial, sans-serif',
    },
    cabecera: {
        backgroundColor: '#800080',
        color: 'white',
        padding: '12px 15px',
    },
    celda: {
        border: '1px solid #ddd',
        padding: '10px 15px',
        textAlign: 'left',
        backgroundColor: 'white',
    },
    celdaImpar: {
        backgroundColor: '#f2e5ff',
    },
    titulo: {
        textAlign: 'center',
        color: '#333',
        fontFamily: '"Segoe UI", Arial, sans-serif',
        margin: '20px 0',
        fontSize: '24px',
    },
    botonEliminar: {
        cursor: 'pointer',
        backgroundColor: '#ff0000',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        padding: '5px 10px',
        marginRight: '5px',
    },
    botonEditar: {
        cursor: 'pointer',
        backgroundColor: '#008000',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        padding: '5px 10px',
    },
    formulario: {
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        maxWidth: '500px',
        margin: '20px auto',
    },
    formGroup: {
        margin: '10px 0',
    },
    label: {
        display: 'block',
        marginBottom: '5px',
        fontWeight: 'bold',
    },
    input: {
        width: '100%',
        padding: '8px',
        margin: '5px 0 15px 0',
        display: 'inline-block',
        border: '1px solid #ccc',
        borderRadius: '4px',
        boxSizing: 'border-box',
    },
    submitButton: {
        width: '100%',
        backgroundColor: '#4CAF50',
        color: 'white',
        padding: '14px 20px',
        margin: '8px 0',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
    },
    cancelButton: {
        width: '100%',
        backgroundColor: '#f44336',
        color: 'white',
        padding: '14px 20px',
        margin: '8px 0',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
    },
};

function CrudProductos() {
  const navigate = useNavigate();
    const [productos, setProductos] = useState([]);
    const [productoEditar, setProductoEditar] = useState(null);

    useEffect(() => {
        cargarProductos();
    }, []);

    const cargarProductos = async () => {
        try {
            const response = await axios.get('https://backend-render-qavo.onrender.com/api/productos');
            setProductos(response.data);
        } catch (error) {
            console.error("Error al cargar los productos", error);
        }
    };
    const goBack = () => {
      navigate(-1);
    };

    const eliminarProducto = async (id) => {
        try {
            await axios.delete(`https://backend-render-qavo.onrender.com/api/productos/${id}`);
            cargarProductos();
        } catch (error) {
            console.error("Error al eliminar el producto", error);
        }
    };

    const mostrarFormularioEdicion = (producto) => {
        setProductoEditar(producto);
    };

    const editarProducto = async (e) => {
      e.preventDefault();
      // Asegúrate de que la URL y el objeto enviado son correctos
      const url = `https://backend-render-qavo.onrender.com/api/productos/${productoEditar._id}`;
      const datosActualizados = {
          nombre: productoEditar.nombre,
          descripcion: productoEditar.descripcion,
          precio: productoEditar.precio,
          categoria: productoEditar.categoria,
          // Añade más campos si es necesario
      };
  
      try {
          const response = await axios.patch(url, datosActualizados);
          if (response.status === 200) {
              alert("Producto actualizado con éxito");
              cargarProductos(); // Recargar la lista de productos para mostrar los cambios
              setProductoEditar(null); // Cerrar formulario de edición
          } else {
              // Manejar la respuesta si el servidor devuelve un estado inesperado
              alert("Error al actualizar el producto. Por favor, inténtalo de nuevo.");
          }
      } catch (error) {
          console.error("Error al actualizar el producto", error);
          alert("Error al actualizar el producto. Por favor, revisa la consola para más detalles.");
      }
  };
  

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setProductoEditar((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    return (
        <div style={estilos.contenedor}>
           <ArrowLeftOutlined className="back-icones" onClick={goBack} />
            <h2 style={estilos.titulo}>Productos</h2>
            <table style={estilos.tabla}>
                <thead>
                    <tr>
                        <th style={estilos.cabecera}>Nombre</th>
                        <th style={estilos.cabecera}>Descripción</th>
                        <th style={estilos.cabecera}>Precio</th>
                        <th style={estilos.cabecera}>Categoría</th>
                        <th style={estilos.cabecera}>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {productos.map((producto, index) => (
                        <tr key={producto._id} style={index % 2 === 0 ? estilos.celda : {...estilos.celda, ...estilos.celdaImpar}}>
                            <td style={estilos.celda}>{producto.nombre}</td>
                            <td style={estilos.celda}>{producto.descripcion}</td>
                            <td style={estilos.celda}>{producto.precio}</td>
                            <td style={estilos.celda}>{producto.categoria}</td>
                            <td style={estilos.celda}>
                                <button style={estilos.botonEliminar} onClick={() => eliminarProducto(producto._id)}>Eliminar</button>
                                <button style={estilos.botonEditar} onClick={() => mostrarFormularioEdicion(producto)}>Editar</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {productoEditar && (
                <div style={estilos.formulario}>
                    <h3>Editar Producto</h3>
                    <form onSubmit={editarProducto}>
                        <div style={estilos.formGroup}>
                            <label style={estilos.label} htmlFor="nombre">Nombre</label>
                            <input style={estilos.input} type="text" id="nombre" name="nombre" value={productoEditar.nombre} onChange={handleInputChange} required />
                        </div>
                        <div style={estilos.formGroup}>
                            <label style={estilos.label} htmlFor="descripcion">Descripción</label>
                            <input style={estilos.input} type="text" id="descripcion" name="descripcion" value={productoEditar.descripcion} onChange={handleInputChange} required />
                        </div>
                        <div style={estilos.formGroup}>
                            <label style={estilos.label} htmlFor="precio">Precio</label>
                            <input style={estilos.input} type="number" id="precio" name="precio" value={productoEditar.precio} onChange={handleInputChange} required />
                        </div>
                        <div style={estilos.formGroup}>
                            <label style={estilos.label} htmlFor="categoria">Categoría</label>
                            <input style={estilos.input} type="text" id="categoria" name="categoria" value={productoEditar.categoria} onChange={handleInputChange} required />
                        </div>
                        <button style={estilos.submitButton} type="submit">Guardar Cambios</button>
                        <button style={estilos.cancelButton} onClick={() => setProductoEditar(null)}>Cancelar</button>
                    </form>
                </div>
            )}
        </div>
    );
}

function CrudinsertarProducto() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    nombre: '',
    descripcion: '',
    precio: '',
    categoria: '',
    fotos: [],
    colores: [{ nombre: '' }],
  });
  const goBack = () => {
    navigate(-1);
  };

  const [imageFiles, setImageFiles] = useState([]);
  const [section, setSection] = useState(0);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.includes('colores')) {
      let fieldName = name.split('.')[0];
      let index = parseInt(name.split('.')[1], 10);
      let items = [...formData[fieldName]];
      items[index] = { nombre: value };
      setFormData({
        ...formData,
        [fieldName]: items,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleFileChange = (e, index) => {
    const files = e.target.files;
    if (files && files[0]) {
      let newImageFiles = [...imageFiles];
      newImageFiles[index] = files[0];
      setImageFiles(newImageFiles);
    }
  };

  const addNewItem = (field) => {
    if (field === 'fotos') {
      setImageFiles([...imageFiles, undefined]);
      setFormData({
        ...formData,
        [field]: [...formData[field], { url: '' }],
      });
    } else {
      setFormData({
        ...formData,
        [field]: [...formData[field], { nombre: '' }],
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const imageUploadPromises = imageFiles.map(file => {
      if (file) {
        const formData = new FormData();
        formData.append('imagen', file);
        return axios.post('https://backend-render-qavo.onrender.com/api/imagenes/upload', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        }).then(response => response.data.url);
      }
      return Promise.resolve('');
    });

    const imageUrls = await Promise.all(imageUploadPromises);

    const newFormData = {
      ...formData,
      fotos: imageUrls.map(url => (url ? { url } : null)).filter(item => item)
    };

    try {
      await axios.post('https://backend-render-qavo.onrender.com/api/productos', newFormData);
      alert('Producto creado con éxito.');
    } catch (error) {
      console.error('Error al crear el producto:', error);
      alert('Error al crear el producto.');
    }
  };

  const nextSection = () => {
    setSection((currentSection) => currentSection + 1);
  };

  const prevSection = () => {
    setSection((currentSection) => Math.max(currentSection - 1, 0));
  };

  return (
    <div style={estilos.formulario}>
       <ArrowLeftOutlined className="back-icones" onClick={goBack} />
      <h2>Crear Nuevo Producto</h2>
      <form onSubmit={handleSubmit}>
        {section === 0 && (
          <>
            <div style={estilos.formGroup}>
              <label style={estilos.label}>Nombre</label>
              <input
                style={estilos.input}
                type="text"
                name="nombre"
                value={formData.nombre}
                onChange={handleChange}
                required
              />
            </div>
            <div style={estilos.formGroup}>
              <label style={estilos.label}>Descripción</label>
              <input
                style={estilos.input}
                type="text"
                name="descripcion"
                value={formData.descripcion}
                onChange={handleChange}
                required
              />
            </div>
            <div style={estilos.formGroup}>
              <label style={estilos.label}>Precio</label>
              <input
                style={estilos.input}
                type="number"
                name="precio"
                value={formData.precio}
                onChange={handleChange}
                required
              />
            </div>
            <div style={estilos.formGroup}>
              <label style={estilos.label}>Categoría</label>
              <input
                style={estilos.input}
                type="text"
                name="categoria"
                value={formData.categoria}
                onChange={handleChange}
                required
              />
            </div>
            <button type="button" onClick={nextSection} style={estilos.submitButton}>Siguiente</button>
          </>
        )}
        {section === 1 && (
          <>
            {formData.fotos.map((foto, index) => (
              <div style={estilos.formGroup} key={`foto-${index}`}>
                <label style={estilos.label}>{`Foto ${index + 1}`}</label>
                <input
                  style={estilos.input}
                  type="file"
                  onChange={(e) => handleFileChange(e, index)}
                  required
                />
              </div>
            ))}
            <button type="button" onClick={() => addNewItem('fotos')} style={estilos.submitButton}>Añadir otra foto</button>
            <button type="button" onClick={prevSection} style={estilos.cancelButton}>Anterior</button>
            <button type="button" onClick={nextSection} style={estilos.submitButton}>Siguiente</button>
          </>
        )}
        {section === 2 && (
          <>
            {formData.colores.map((color, index) => (
              <div style={estilos.formGroup} key={`color-${index}`}>
                <label style={estilos.label}>{`Color ${index + 1}`}</label>
                <input
                  style={estilos.input}
                  type="text"
                  name={`colores.${index}.nombre`}
                  value={color.nombre}
                  onChange={handleChange}
                  required
                />
              </div>
            ))}
            <button type="button" onClick={() => addNewItem('colores')} style={estilos.submitButton}>Añadir otro color</button>
            <button type="button" onClick={prevSection} style={estilos.cancelButton}>Anterior</button>
            <button type="submit" style={estilos.submitButton}>Crear Producto</button>
          </>
        )}
      </form>
    </div>
  );
}

 
export  {CrudProductos, CrudinsertarProducto};
