import React, { useRef, useEffect, useState } from 'react';

const FoodTank = ({ level, maxLevel }) => {
  const canvasRef = useRef(null);
  const [currentLevel, setCurrentLevel] = useState((level / maxLevel) * 100);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentLevel((prevLevel) => {
        const targetLevel = (level / maxLevel) * 100;
        const delta = targetLevel - prevLevel;
        if (Math.abs(delta) < 0.5) {
          clearInterval(intervalId);
          return targetLevel;
        }
        return prevLevel + delta * 0.05;
      });
    }, 16);

    return () => clearInterval(intervalId);
  }, [level, maxLevel]);

  const drawFood = (ctx, height) => {
    const width = ctx.canvas.width;
    const foodHeight = height * (currentLevel / 100);
    ctx.clearRect(0, 0, width, height); // Limpiar el canvas

    // Crea un gradiente lineal
    const gradient = ctx.createLinearGradient(0, height, 0, height - foodHeight);
    // Colores del gradiente
    if (currentLevel > 20) {
      gradient.addColorStop(0, '#F4A261'); // Color normal (naranja)
      gradient.addColorStop(1, '#E9C46A'); // Color de transición
    } else {
      gradient.addColorStop(0, '#E76F51'); // Color cuando está casi vacío (rojo)
      gradient.addColorStop(1, '#F4A261'); // Color de transición hacia casi vacío
    }

    ctx.fillStyle = gradient;
    ctx.fillRect(0, height - foodHeight, width, foodHeight); // Dibujar el nivel de comida con el gradiente
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    let frameId;

    const render = () => {
      drawFood(context, canvas.height);
      frameId = requestAnimationFrame(render);
    };

    frameId = requestAnimationFrame(render);

    return () => cancelAnimationFrame(frameId);
  }, [currentLevel]);

  return (
    <div style={{ width: '100px', height: '200px', position: 'relative', border: '5px solid #E76F51', borderRadius: '25px', overflow: 'hidden' }}>
      <canvas ref={canvasRef} width="100" height="200" /> 
      <div style={{ position: 'absolute', top: '10px', left: '50%', transform: 'translateX(-50%)', color: '#E76F51', fontWeight: 'bold' }}>
        {Math.round(currentLevel)}%
      </div>
    </div>
  );
};

export default FoodTank;
