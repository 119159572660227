import React from 'react';
import WaterDispenserControl from './WaterDispenserControl';

const DispenserControlContainer = () => {
  return (
    <div style={{
      maxWidth: '600px',
      margin: '40px auto',
      padding: '20px',
      textAlign: 'center',
      borderRadius: '15px',
    }}>
      <WaterDispenserControl />
    </div>
  );
};

export default DispenserControlContainer;
