import { Link } from "react-router-dom";
import './Styles_Shared/Registro.css'
import { useNavigate, useParams } from "react-router-dom";

function MenuPassword() {
  
 



  
  return (
    <>
    <div className="estiloContenedor-3">
      
    <form>
      <div className="estiloCampo">
        <h1 className="estiloTitulo">Selecciona el metodo de recuperación</h1>
      
      </div>
      <Link to={`/validar_correo/${1}`}>
        <button type="submit" className="estiloBoton">
        &#10067; Pregunta secreta
        </button>
      </Link>
      <br></br><br></br>
       <Link to={`/validar_correo/${2}`}>
        <button type="submit" className="estiloBoton">
        &#9993; Código de verificación
        </button>
      </Link>
    </form>
    </div>
    </>
  );
};

export default MenuPassword;
