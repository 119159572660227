import React, { useEffect, useState } from 'react';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import bcrypt from "bcryptjs";


const estilos = {
    contenedor: {
        maxWidth: '100%',
        padding: '200px 40px',
    },
    tabla: {
        width: '100%',
        borderCollapse: 'collapse',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        margin: '20px 0',
        fontFamily: '"Segoe UI", Arial, sans-serif',
    },
    cabecera: {
        backgroundColor: '#800080',
        color: 'white',
        padding: '12px 15px',
    },
    celda: {
        border: '1px solid #ddd',
        padding: '10px 15px',
        textAlign: 'left',
        backgroundColor: 'white',
    },
    celdaImpar: {
        backgroundColor: '#f2e5ff',
    },
    titulo: {
        textAlign: 'center',
        color: '#333',
        fontFamily: '"Segoe UI", Arial, sans-serif',
        margin: '20px 0',
        fontSize: '24px',
    },
    botonEliminar: {
        cursor: 'pointer',
        backgroundColor: '#ff0000',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        padding: '5px 10px',
        marginRight: '5px',
    },
    botonEditar: {
        cursor: 'pointer',
        backgroundColor: '#008000',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        padding: '5px 10px',
    },
    formulario: {
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        maxWidth: '500px',
        margin: '20px auto',
    },
    formGroup: {
        margin: '10px 0',
    },
    label: {
        display: 'block',
        marginBottom: '5px',
        fontWeight: 'bold',
    },
    input: {
        width: '100%',
        padding: '8px',
        margin: '5px 0 15px 0',
        display: 'inline-block',
        border: '1px solid #ccc',
        borderRadius: '4px',
        boxSizing: 'border-box',
    },
    submitButton: {
        width: '100%',
        backgroundColor: '#4CAF50',
        color: 'white',
        padding: '14px 20px',
        margin: '8px 0',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
    },
    cancelButton: {
        width: '100%',
        backgroundColor: '#f44336',
        color: 'white',
        padding: '14px 20px',
        margin: '8px 0',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
    }, formulario: {
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        maxWidth: '500px',
        margin: '20px auto',
    },
    formGroup: {
        margin: '10px 0',
    },
    label: {
        display: 'block',
        marginBottom: '5px',
        fontWeight: 'bold',
    },
    input: {
        width: '100%',
        padding: '8px',
        margin: '5px 0 15px 0',
        display: 'inline-block',
        border: '1px solid #ccc',
        borderRadius: '4px',
        boxSizing: 'border-box',
    },
    submitButton: {
        width: '100%',
        backgroundColor: '#4CAF50',
        color: 'white',
        padding: '14px 20px',
        margin: '8px 0',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
    },
    cancelButton: {
        width: '100%',
        backgroundColor: '#f44336',
        color: 'white',
        padding: '14px 20px',
        margin: '8px 0',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
    }
};

function CrudUsuarios() {
    const [usuarios, setUsuarios] = useState([]);
    const [usuarioEditar, setUsuarioEditar] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        fetch('https://backend-render-qavo.onrender.com/api/usuarios')
            .then(response => response.json())
            .then(data => setUsuarios(data))
            .catch(error => console.error("Error al cargar los usuarios:", error));
    }, []);
    const goBack = () => {
        navigate(-1);
    }

    const eliminarUsuario = (id) => {
        fetch(`https://backend-render-qavo.onrender.com/api/usuarios/${id}`, {
            method: 'DELETE',
        })
        .then(response => {
            if (response.ok) {
                setUsuarios(usuarios.filter(usuario => usuario._id !== id));
            } else {
                throw new Error('Failed to delete the user.');
            }
        })
        .catch(error => console.error("Error deleting user:", error));
    };

    const mostrarFormularioEdicion = (usuario) => {
        const datosEdicion = {
            ...usuario,
            nombre: usuario.datos_administrativos?.nombre ?? '',
            apellido: usuario.datos_administrativos?.apellido ?? '',
            telefono: usuario.datos_administrativos?.telefono ?? '',
            rfc: usuario.datos_administrativos?.rfc ?? '',
        };
        setUsuarioEditar(datosEdicion);
    };

    const editarUsuario = (e) => {
        e.preventDefault();
        const datosParaActualizar = {
            username: usuarioEditar.username,
            correo: usuarioEditar.correo,
            datos_administrativos: {
                nombre: usuarioEditar.nombre,
                apellido: usuarioEditar.apellido,
                telefono: usuarioEditar.telefono,
                rfc: usuarioEditar.rfc,
            },
        };

        fetch(`https://backend-render-qavo.onrender.com/api/usuarios/${usuarioEditar._id}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(datosParaActualizar),
        })
        .then(response => {
            if (response.ok) {
                setUsuarios(usuarios.map((usuario) => usuario._id === usuarioEditar._id ? { ...usuario, ...datosParaActualizar } : usuario));
                setUsuarioEditar(null); // Cerrar formulario de edición
            } else {
                throw new Error('Failed to update the user.');
            }
        })
        .catch(error => console.error("Error updating user:", error));
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setUsuarioEditar((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
     


    return (
        <div style={estilos.contenedor}>
                     <ArrowLeftOutlined className="back-icones" onClick={goBack} />

            <h2 style={estilos.titulo}>Administradores</h2>
            <table style={estilos.tabla}>
                <thead>
                    <tr>
                        <th style={estilos.cabecera}>Username</th>
                        <th style={estilos.cabecera}>Correo</th>
                        <th style={estilos.cabecera}>Nombre</th>
                        <th style={estilos.cabecera}>Apellido</th>
                        <th style={estilos.cabecera}>Teléfono</th>
                        <th style={estilos.cabecera}>RFC</th>
                        <th style={estilos.cabecera}>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {usuarios.filter(usuario => (usuario.tipo || []).includes('administrador')).map((usuario, index) => (
                        <tr key={usuario._id} style={index % 2 === 0 ? {} : estilos.celdaImpar}>
                            <td style={estilos.celda}>{usuario.username}</td>
                            <td style={estilos.celda}>{usuario.correo}</td>
                            <td style={estilos.celda}>{usuario.datos_administrativos?.nombre}</td>
                            <td style={estilos.celda}>{usuario.datos_administrativos?.apellido}</td>
                            <td style={estilos.celda}>{usuario.datos_administrativos?.telefono}</td>
                            <td style={estilos.celda}>{usuario.datos_administrativos?.rfc}</td>
                            <td style={estilos.celda}>
                                <button style={estilos.botonEliminar} onClick={() => eliminarUsuario(usuario._id)}>Eliminar</button>
                                <button style={estilos.botonEditar} onClick={() => mostrarFormularioEdicion(usuario)}>Editar</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {usuarioEditar && (
                <div style={estilos.formulario}>
                    <form onSubmit={editarUsuario}>
                        <div style={estilos.formGroup}>
                            <label style={estilos.label} htmlFor="username">Username:</label>
                            <input style={estilos.input} type="text" name="username" id="username" placeholder="Username" value={usuarioEditar.username || ''} onChange={handleInputChange} />
                        </div>
                        <div style={estilos.formGroup}>
                            <label style={estilos.label} htmlFor="correo">Correo:</label>
                            <input style={estilos.input} type="email" name="correo" id="correo" placeholder="Correo" value={usuarioEditar.correo || ''} onChange={handleInputChange} />
                        </div>
                        <div style={estilos.formGroup}>
                            <label style={estilos.label} htmlFor="nombre">Nombre:</label>
                            <input style={estilos.input} type="text" name="nombre" id="nombre" placeholder="Nombre" value={usuarioEditar.nombre || ''} onChange={handleInputChange} />
                        </div>
                        <div style={estilos.formGroup}>
                            <label style={estilos.label} htmlFor="apellido">Apellido:</label>
                            <input style={estilos.input} type="text" name="apellido" id="apellido" placeholder="Apellido" value={usuarioEditar.apellido || ''} onChange={handleInputChange} />
                        </div>
                        <div style={estilos.formGroup}>
                            <label style={estilos.label} htmlFor="telefono">Teléfono:</label>
                            <input style={estilos.input} type="text" name="telefono" id="telefono" placeholder="Teléfono" value={usuarioEditar.telefono || ''} onChange={handleInputChange} />
                        </div>
                        <div style={estilos.formGroup}>
                            <label style={estilos.label} htmlFor="rfc">RFC:</label>
                            <input style={estilos.input} type="text" name="rfc" id="rfc" placeholder="RFC" value={usuarioEditar.rfc || ''} onChange={handleInputChange} />
                        </div>
                        <button style={estilos.submitButton} type="submit">Guardar Cambios</button>
                        <button style={estilos.cancelButton} onClick={() => setUsuarioEditar(null)}>Cancelar</button>
                    </form>
                </div>
            )}
        </div>
    );
}


function CrudInsertarAdmi() {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        
        username: '',
        password: '',
        tipo: ['administrador'],
        correo: '',
        datos_administrativos: {
            nombre: '',
            apellido: '',
            telefono: '',
            rfc: '',
        },
    });
    const goBack = () => {
        navigate(-1);
    }
    const [section, setSection] = useState(0);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name.includes("datos_administrativos.")) {
            const fieldName = name.split(".")[1];
            setFormData({
                ...formData,
                datos_administrativos: {
                    ...formData.datos_administrativos,
                    [fieldName]: value,
                },
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Cantidad de rondas para generar la sal. Cuanto mayor sea el número, más seguro y más lento será el proceso de cifrado.
            const saltRounds = 10;
            // Cifrar la contraseña antes de enviarla.
            const hashedPassword = await bcrypt.hash(formData.password, saltRounds);
            
            // Actualizar formData con la contraseña cifrada
            const updatedFormData = {
                ...formData,
                password: hashedPassword,
            };
    
            // Aquí puedes continuar con el envío de los datos actualizados
            await axios.post('https://backend-render-qavo.onrender.com/api/usuarios', updatedFormData);
            alert('Usuario creado con éxito.');
            // Opcional: Reiniciar formulario o redirigir al usuario
        } catch (error) {
            console.error('Error al crear el usuario:', error);
            alert('Error al crear el usuario.');
        }
    };

    const nextSection = () => {
        setSection((currentSection) => currentSection + 1);
    };

    const prevSection = () => {
        setSection((currentSection) => Math.max(currentSection - 1, 0));
    };

    return (
        <div style={{ padding: '20px' }}>
                      <ArrowLeftOutlined className="back-icones" onClick={goBack} />
            <h2 style={{ textAlign: 'center', margin: '20px 0' }}>Crear Nuevo Usuario Administrativo</h2>
            <form onSubmit={handleSubmit} style={estilos.formulario}>
                {section === 0 && (
                    <>
                        <h3>Información Básica</h3>
                        <div style={estilos.formGroup}>
                            <label style={estilos.label}>Nombre de Usuario:
                                <input type="text" name="username" value={formData.username} onChange={handleChange} required style={estilos.input} />
                            </label>
                        </div>
                        <div style={estilos.formGroup}>
                            <label style={estilos.label}>Correo Electrónico:
                                <input type="email" name="correo" value={formData.correo} onChange={handleChange} required style={estilos.input} />
                            </label>
                        </div>
                        <button type="button" onClick={nextSection} style={estilos.submitButton}>Siguiente</button>
                    </>
                )}
                {section === 1 && (
                    <>
                        <h3>Datos Administrativos</h3>
                        <div style={estilos.formGroup}>
                            <label style={estilos.label}>Nombre:
                                <input type="text" name="datos_administrativos.nombre" value={formData.datos_administrativos.nombre} onChange={handleChange} required style={estilos.input} />
                            </label>
                        </div>
                        <div style={estilos.formGroup}>
                            <label style={estilos.label}>Apellido:
                                <input type="text" name="datos_administrativos.apellido" value={formData.datos_administrativos.apellido} onChange={handleChange} required style={estilos.input} />
                            </label>
                        </div>
                        <div style={estilos.formGroup}>
                            <label style={estilos.label}>RFC:
                                <input type="text" name="datos_administrativos.rfc" value={formData.datos_administrativos.rfc} onChange={handleChange} required style={estilos.input} />
                            </label>
                        </div>
                        <button type="button" onClick={prevSection} style={estilos.cancelButton}>Anterior</button>
                        <button type="button" onClick={nextSection} style={estilos.submitButton}>Siguiente</button>
                    </>
                )}
                {section === 2 && (
                    <>
                        <h3>Contacto y Seguridad</h3>
                        <div style={estilos.formGroup}>
                            <label style={estilos.label}>Teléfono:
                                <input type="tel" name="datos_administrativos.telefono" value={formData.datos_administrativos.telefono} onChange={handleChange} required style={estilos.input} />
                            </label>
                        </div>
                        <div style={estilos.formGroup}>
                            <label style={estilos.label}>Contraseña:
                                <input type="password" name="password" value={formData.password} onChange={handleChange} required style={estilos.input} />
                            </label>
                        </div>
                        <button type="button" onClick={prevSection} style={estilos.cancelButton}>Anterior</button>
                        <button type="submit" style={estilos.submitButton}>Crear Usuario</button>
                    </>
                )}
            </form>
        </div>
    );
}
export  {CrudUsuarios  ,CrudInsertarAdmi};


