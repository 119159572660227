import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';

const estilos = {
  contenedor: {
    maxWidth: '100%',
    padding: '20px 40px',
  },
  tabla: {
    width: '100%',
    borderCollapse: 'collapse',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    margin: '20px 0',
    fontFamily: '"Segoe UI", Arial, sans-serif',
  },
  cabecera: {
    backgroundColor: '#800080',
    color: 'white',
    padding: '12px 15px',
  },
  celda: {
    border: '1px solid #ddd',
    padding: '10px 15px',
    textAlign: 'left',
    backgroundColor: 'white',
  },
  titulo: {
    textAlign: 'center',
    color: '#333',
    fontFamily: '"Segoe UI", Arial, sans-serif',
    margin: '20px 0',
    fontSize: '24px',
  },
  botonEditar: {
    cursor: 'pointer',
    backgroundColor: '#008000',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    padding: '5px 10px',
    display: 'block',
    margin: '20px auto',
    width: 'fit-content',
  },
  formulario: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    maxWidth: '500px',
    margin: '20px auto',
  },
  formGroup: {
    margin: '10px 0',
  },
  label: {
    display: 'block',
    marginBottom: '5px',
    fontWeight: 'bold',
  },
  input: {
    width: '100%',
    padding: '8px',
    margin: '5px 0 15px 0',
    display: 'inline-block',
    border: '1px solid #ccc',
    borderRadius: '4px',
    boxSizing: 'border-box',
  },
  textArea: {
    width: '100%',
    height: '100px',
    padding: '8px',
    margin: '5px 0 15px 0',
    display: 'inline-block',
    border: '1px solid #ccc',
    borderRadius: '4px',
    boxSizing: 'border-box',
  },
  submitButton: {
    width: '100%',
    backgroundColor: '#4CAF50',
    color: 'white',
    padding: '14px 20px',
    margin: '8px 0',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
};

function CrudPoliticas() {
  const navigate = useNavigate();
  const [info, setInfo] = useState(null);
  const [editar, setEditar] = useState(false);
  const [datosFormulario, setDatosFormulario] = useState({
    titulo: '',
    descripcion: '',
  });

  useEffect(() => {
    cargarPoliticasPrivacidad();
  }, []);

  const cargarPoliticasPrivacidad = async () => {
    try {
      const response = await axios.get('https://backend-render-qavo.onrender.com/api/politicasPrivacidad');
      if (response.data.length > 0) {
        const data = response.data[0];
        setInfo(data);
        setDatosFormulario({
          titulo: data.titulo || '',
          descripcion: data.descripcion || '',
        });
      }
    } catch (error) {
      console.error("Error al cargar Políticas de Privacidad", error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDatosFormulario({
      ...datosFormulario,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const apiUrl = info && info._id ? `https://backend-render-qavo.onrender.com/api/politicasPrivacidad/${info._id}` : 'https://backend-render-qavo.onrender.com/api/politicasPrivacidad';
    const method = info && info._id ? 'patch' : 'post';

    try {
      const response = await axios[method](apiUrl, datosFormulario);
      if (response.status === 200 || response.status === 201) {
        alert('Información de Políticas de Privacidad actualizada con éxito');
        cargarPoliticasPrivacidad(); // Recargar la información actualizada
        setEditar(false); // Salir del modo de edición
      } else {
        alert('La actualización no se completó. Por favor, inténtalo de nuevo.');
      }
    } catch (error) {
      console.error("Error al actualizar Políticas de Privacidad: ", error);
      alert('Ocurrió un error al intentar guardar los cambios. Por favor, verifica la consola para más detalles.');
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  return (

    <>
       <ArrowLeftOutlined className="back-icones" onClick={goBack} />
   
    <div style={estilos.contenedor}>
      <h2 style={estilos.titulo}>Políticas de Privacidad</h2>
      {!editar ? (
        <>
          <table style={estilos.tabla}>
            <tbody>
              <tr>
                <th style={estilos.cabecera}>Título</th>
                <td style={estilos.celda}>{info?.titulo || "Cargando..."}</td>
              </tr>
              <tr>
                <th style={estilos.cabecera}>Descripción</th>
                <td style={estilos.celda}>{info?.descripcion}</td>
              </tr>
            </tbody>
          </table>
          <button style={estilos.botonEditar} onClick={() => setEditar(true)}>Editar</button>
        </>
      ) : (
        <div style={estilos.formulario}>
          <form onSubmit={handleSubmit}>
            <div style={estilos.formGroup}>
              <label style={estilos.label}>Título</label>
              <input
                style={estilos.input}
                type="text"
                name="titulo"
                value={datosFormulario.titulo}
                onChange={handleInputChange}
              />
            </div>
            <div style={estilos.formGroup}>
              <label style={estilos.label}>Descripción</label>
              <textarea
                style={estilos.textArea}
                name="descripcion"
                value={datosFormulario.descripcion}
                onChange={handleInputChange}
              ></textarea>
            </div>
            <button style={estilos.submitButton} type="submit">Guardar Cambios</button>
          </form>
        </div>
      )}
    </div>
    </>
  );
}

export default CrudPoliticas;
