import React, { useState, useEffect } from 'react';
import { Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';


import {
  BarsOutlined,
  AppstoreOutlined,
  UserOutlined,
  HomeOutlined,
  CloudServerOutlined,
} from '@ant-design/icons';
import logo from './../../imagenes/LogoMascota.png';
import { useNavigate } from 'react-router-dom';

const { Header } = Layout;
const { SubMenu } = Menu;

const EncabezadoPublico = () => {
  const navigate = useNavigate();
  const [busqueda, setBusqueda] = useState('');
  const [categorias, setCategorias] = useState([]);
  const [subMenuVisible, setSubMenuVisible] = useState(false);

  useEffect(() => {
    // Actualiza esta URL con la de tu API
    fetch('https://backend-render-qavo.onrender.com/api/productos/categorias')
      .then((response) => response.json())
      .then((data) => setCategorias(data))
      .catch((error) => console.error('Error fetching categorias:', error));
  }, []);

  const manejarBusqueda = (e) => {
    e.preventDefault();
    console.log('Buscar:', busqueda);
    // Aquí iría la lógica de búsqueda
  };

  const toggleSubMenu = () => {
    setSubMenuVisible(!subMenuVisible);
  };

  const handleMenuClick = (e) => {
    switch (e.key) {
      case '1':
        navigate('/');
        break;
      case '20':
        navigate('/login');
        break;
      default:
        console.log('No se reconoce la acción del menú');
    }
  };

  // Estilos definidos como objetos JavaScript


  return (
    <Layout className="layout">
      <Header style={{ padding: 45, display: 'flex', alignItems: 'center', backgroundColor: '#FFA500' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
          <img src={logo} alt="Logo" style={{ height: '90px', marginRight: '10px' }} />
          <span style={{ color: 'white', fontSize: '30px', fontWeight: 'bold' }}>AmiMascota</span>
        </div>

        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']} style={{ lineHeight: '90px', fontSize: '18px', backgroundColor: '#FFA500', marginLeft: 'auto' }} onClick={handleMenuClick}>

          <Menu.Item key="1" icon={<HomeOutlined />}>
            Inicio
          </Menu.Item>
          <SubMenu key="sub1" icon={<AppstoreOutlined />} title="Accesorio" onMouseEnter={toggleSubMenu} onMouseLeave={toggleSubMenu}>
            {categorias.map((categoria, index) => (
              <Menu.Item key={index + 2} icon={<BarsOutlined />}>
                <Link to={`/categorias/${categoria}`}>
                  {categoria}
                </Link>
              </Menu.Item>
            ))}
          </SubMenu>
          <Menu.Item key="20" icon={<UserOutlined />}>
            Iniciar sesión
          </Menu.Item>
        </Menu>
      </Header>
    </Layout>
  );
};

export default EncabezadoPublico;