import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import './InformacionEmpresaCliente.css';

const InformacionEmpresaCliente = () => {
    const [datosMisionVision, setDatosMisionVision] = useState([]);
    const navigate = useNavigate(); // Obtener la función navigate

    useEffect(() => {
        fetch('https://backend-render-qavo.onrender.com/api/misionVision')
            .then(response => {
                if (!response.ok) {
                    throw new Error(`Error fetching misionVision: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                console.log('Data received:', data);
                setDatosMisionVision(data);
            })
            .catch(error => {
                console.error(error);
            });
    }, []);

    // Función para retroceder en la navegación
    const goBack = () => {
        navigate(-1);
    };

    return (
        <div className="container">
            {/* Agregar el icono de retroceso con la función goBack */}
            <ArrowLeftOutlined className="back-icon" onClick={goBack} />
            {datosMisionVision.length === 0 ? (
                <p>Loading...</p>
            ) : (
                datosMisionVision.map((item, index) => (
                    <div key={index} className="info-box">
                        <h2 className="organization-name">¿Quiénes somos?</h2>
                        <p>{item.nombreOrganizacion}</p>

                        <div className="mission-vision">
                            <div>
                                <h3>Misión</h3>
                                <p>{item.mision}</p>
                            </div>

                            <div>
                                <h3>Visión</h3>
                                <p>{item.vision}</p>
                            </div>
                        </div>
                    </div>
                ))
            )}
        </div>
    );
};

export default InformacionEmpresaCliente;




