import React, { useState } from 'react';

const estilos = {
    formulario: {
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        maxWidth: '500px',
        margin: '20px auto',
    },
    formGroup: {
        margin: '10px 0',
    },
    label: {
        display: 'block',
        marginBottom: '5px',
        fontWeight: 'bold',
    },
    input: {
        width: '100%',
        padding: '8px',
        margin: '5px 0 15px 0',
        display: 'inline-block',
        border: '1px solid #ccc',
        borderRadius: '4px',
        boxSizing: 'border-box',
    },
    submitButton: {
        width: '100%',
        backgroundColor: '#4CAF50',
        color: 'white',
        padding: '14px 20px',
        margin: '8px 0',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
    },
    titulo: {
        textAlign: 'center',
        color: '#333',
        margin: '0 0 20px 0', // Ajusta el margen según necesites
        fontSize: '24px',
    },
};

const AgregarIot = () => {
  const [nombre, setNombre] = useState('');
  const [codigo, setCodigo] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Verificar la validez de los campos antes de enviar
    if (nombre.trim().length < 3) {
      alert('El nombre debe tener al menos 3 caracteres.');
      return;
    }

    if (codigo.trim().length < 4) {
      alert('El código debe tener al menos 4 caracteres.');
      return;
    }

    const nuevaDispensadora = {
      nombre,
      tanques: { comida: null, agua: null },
      platos: { comida: null, agua: null },
      codigo,
      assignedTo: null,
    };
  
    try {
      const response = await fetch('https://backend-render-qavo.onrender.com/api/dispositivo', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(nuevaDispensadora),
      });
      
      if (response.ok) {
        const data = await response.json();
        console.log('Dispensadora registrada con éxito:', data);
        alert('Dispensadora registrada con éxito. ID: ' + data.id);
      } else {
        const errorData = await response.json();
        
        alert('Dispositivo registrado con exito.');
      }
    } catch (error) {
      console.error('Error al conectar con el servidor:', error);
      alert('Error al conectar con el servidor. Por favor, inténtelo de nuevo.');
    }
    
    setNombre('');
    setCodigo('');
  };

  return (
    <div style={estilos.formulario}>
      <h2 style={estilos.titulo}>Agregar Nueva Dispensadora IoT</h2>
      <form onSubmit={handleSubmit}>
        <div style={estilos.formGroup}>
          <label htmlFor="nombre" style={estilos.label}>Nombre:</label>
          <input
            id="nombre"
            type="text"
            value={nombre}
            onChange={(e) => setNombre(e.target.value)}
            required
            style={estilos.input}
          />
        </div>
        <div style={estilos.formGroup}>
          <label htmlFor="codigo" style={estilos.label}>Clave:</label>
          <input
            id="codigo"
            type="text"
            value={codigo}
            onChange={(e) => setCodigo(e.target.value)}
            required
            style={estilos.input}
          />
        </div>
        <button type="submit" style={estilos.submitButton}>Agregar Dispensadora</button>
      </form>
    </div>
  );
};

export default AgregarIot;
