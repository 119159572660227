import React, { useEffect, useState } from "react";
//importamos las libreria de encriptacion
import bcrypt from "bcryptjs"; // Importa la librería bcryptjs para encriptar la contraseña
import "./Styles_Shared/Registro.css";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios"; // Asegúrate de importar axios si no lo has hecho aún
import { error } from "jquery";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import "bootstrap/dist/css/bootstrap.min.css"; // Importar los estilos de Bootstrap

//creamos una instancia para las alertas
const MySwal = withReactContent(Swal);

function FormularioRegistro() {
  const navigate = useNavigate();

  const [preguntas, setPreguntas] = useState([]);
  const [confirmarP, setconfirmarP] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [usuarios, setUsuarios] = useState([]);
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    tipo: ["cliente"],
    correo: "",
    datos_cliente: {
      nombre: "",
      apellidoPaterno: "",
      apellidoMaterno: "",
      telefono: "",
      pregunta: {
        _id: " ",
        respuesta: "",
      },
      direccion: {
        calle: "sin_calle ",
        numero: "sin_numero ",
        colonia: {
          _id: "",
          nombre: "sin_nombre ",
          localidad: {
            _id: "",
            nombre: "sin_nombre",
          },
        },
      },
    },
  });
  const goBack = () => {
    navigate(-1);
  };
  //creamos una funcion 
  useEffect(() => {
    // Realizar la solicitud GET para obtener los usuarios
    axios.get('https://backend-render-qavo.onrender.com/api/usuarios')
      .then(response => {
        // Almacenar los datos de los usuarios en el estado
        setUsuarios(response.data);
      })
      .catch(error => console.error("Error al cargar los usuarios: ", error));
  }, []);






  useEffect(() => {
    fetch("https://backend-render-qavo.onrender.com/api/registro")
      .then((response) => response.json())
      .then((data) => setPreguntas(data))
      .catch((error) => console.error("Error al cargar los usuarios: ", error));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    //enviamos los datos ala base de datos
    if (name.startsWith("datos_cliente")) {
      const fieldName = name.split(".")[1];
      setFormData({
        ...formData,
        datos_cliente: {
          ...formData.datos_cliente,
          [fieldName]: value,
        },
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (formData.password !== confirmarP) {
      setErrorMessage('Las contraseñas no coinciden.');
      return;
    }

    const hashedPassword = await bcrypt.hash(formData.password, 10);
    // Crear un nuevo objeto de datos con la contraseña encriptada
    const formDataWithEncryptedPassword = {
      ...formData,
      password: hashedPassword,
    };
    //hacemos una consulta de correo electronico si no existe
    // Envía formData a tu API
    try {
      const usuarioCorreo= usuarios.find(usuario=>usuario.correo===formData.correo)
      if(usuarioCorreo){
        MySwal.fire({
          icon: "error",
          title: "ERROR.",
          text: "Error el correo ya existe.",
        });
      
        
      }
      else{
      await axios.post(
        "https://backend-render-qavo.onrender.com/api/usuarios",
        formDataWithEncryptedPassword
      );
      // Vaciar el formulario reseteando el estado formData
      setFormData({
        username: "",
        password: "",
        tipo: ["cliente"],
        correo: "",
        datos_cliente: {
          nombre: "",
          apellidoPaterno: "",
          apellidoMaterno: "",
          telefono: "",
          pregunta: {
            _id: " ",
            respuesta: "",
          },
          direccion: {
            calle: "sin_calle ",
            numero: "sin_numero ",
            colonia: {
              _id: "",
              nombre: "sin_nombre ",
              localidad: {
                _id: "",
                nombre: "sin_nombre",
              },
            },
          },
        },
      });

      MySwal.fire({
        title: "Tu registro se realizo correctamente",
        text: " " + formData.username + "",
        icon: "success", // Puedes cambiar el icono: "success", "error", "warning", "info"
        confirmButtonText: "Aceptar",
      });
      navigate("/login");
      // setRedirect(true);
      // Aquí podrías limpiar el formulario o redirigir al usuario
    }
    } catch (error) {
      // Vaciar el formulario reseteando el estado formData
      setFormData({
        username: "",
        password: "",
        tipo: ["cliente"],
        correo: "",
        datos_cliente: {
          nombre: "",
          apellidoPaterno: "",
          apellidoMaterno: "",
          telefono: "",
          pregunta: {
            _id: " ",
            respuesta: " ",
          },
          direccion: {
            calle: "sin_calle ",
            numero: "sin_numero ",
            colonia: {
              _id: "",
              nombre: "sin_nombre ",
              localidad: {
                _id: "",
                nombre: "sin_nombre",
              },
            },
          },
        },
      });
      console.error("Error no te pudistes registrar:", error);
      MySwal.fire({
        icon: "error",
        title: "ERROR.",
        text: "Error no te pudistes registrar.",
      });
    }
  };
  return (
    <>
      <br></br>
      <div className="estiloContenedor">
        <ArrowLeftOutlined className="back-icones" onClick={goBack} />
        <Link to="/">
          <br></br>
          <div className="incon_house">&#127968;</div>
        </Link>
        <h1 className="estiloTitulo">Registro</h1>
        <form onSubmit={handleSubmit} autoComplete="off">
          <div className="estiloCampo">
            <label htmlFor="datos_cliente.nombre" className="estiloEtiqueta">
              &#128100; Nombre
            </label>
            <input
              autoComplete="off"
              type="text"
              placeholder="Nombre"
              name="datos_cliente.nombre"
              className="estiloInput"
              value={formData.datos_cliente.nombre}
              onChange={handleChange}
              title="Solo se aceptan letras"
              required
              maxLength={10}
              pattern="[a-zA-Z]*"
            />
            <br />
            <br />
          </div>

          <div className="estiloCampo">
            <label
              htmlFor="datos_cliente.apellidoPaterno"
              className="estiloEtiqueta"
            >
              &#128100; Apellido Paterno:
            </label>
            <input
              autoComplete="off"
              type="text"
              maxLength={15}
              pattern="[a-zA-Z]*"
              placeholder="Apellido Paterno"
              name="datos_cliente.apellidoPaterno"
              className="estiloInput"
              value={formData.datos_cliente.apellidoPaterno}
              onChange={handleChange}
              title="Solo se aceptan letras"
              required
            />
          </div>
          <div className="estiloCampo">
            <label
              htmlFor="datos_cliente.apellidoMaterno"
              className="estiloEtiqueta"
            >
              &#128100; Apellido Materno:
            </label>
            <input
              autoComplete="off"
              type="text"
              maxLength={15}
              pattern="[a-zA-Z]*"
              name="datos_cliente.apellidoMaterno"
              placeholder="Apellido Materno"
              className="estiloInput"
              title="Solo se aceptan letras"
              value={formData.datos_cliente.apellidoMaterno}
              onChange={handleChange}
              required
            />
          </div>
          <div className="estiloCampo">
            <label htmlFor="correo" className="estiloEtiqueta">
              &#9993; Correo Electrónico:
            </label>
            <input
              autoComplete="off"
              type="email"
              name="correo"
              placeholder="Correo electrónico"
              className="estiloInput"
              value={formData.correo}
              onChange={handleChange}
              required
              title="Formato invalido"
              minLength={8}
              maxLength={30} // Ajustado a un valor razonable, puede ser mayor si lo deseas
              pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$" // Expresión regular para validar correo electrónico
            />
          </div>

          <div className="estiloCampo">
            <label htmlFor="username" className="estiloEtiqueta">
              &#128100; Usuario:
            </label>
            <input
              autoComplete="off"
              type="text"
              name="username"
              placeholder="Usuario"
              className="estiloInput"
              value={formData.username}
              onChange={handleChange}
              maxLength={15}
              pattern="[a-zA-Z0-9]*"
              minLength={4}
              title="Solo se acepta numeros y letras"
              required
            />
          </div>

          <div className="estiloCampo">
            <label htmlFor="datos_cliente.telefono" className="estiloEtiqueta">
              &#128222; Teléfono:
            </label>
            <input
              autoComplete="off"
              type="tel"
              name="datos_cliente.telefono"
              placeholder="Teléfono"
              className="estiloInput"
              value={formData.datos_cliente.telefono}
              onChange={handleChange}
              required
              maxLength={10}
              minLength={10}
              title="solo se aceptan numeros"
              pattern="[0-9]*"
            />
          </div>

          <div className="estiloCampo">
            <label htmlFor="password" className="estiloEtiqueta">
              &#128274; Contraseña:
            </label>
            <input
              autoComplete="off"
              type="password"
              name="password"
             
              className="estiloInput"
              value={formData.password}
              onChange={handleChange}
              minLength={8}
             
              placeholder="Ingrese una contraseña segura"
             pattern="^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
              title="La contraseña debe tener al menos 8 caracteres, incluyendo al menos una letra mayúscula, una letra minúscula, un número y un carácter especial."
              style={{ borderColor: errorMessage ? "red" : "" }}
             
    
              required
            />
          </div>

          <div className="estiloCampo">
            <label htmlFor="confirmarPassword" className="estiloEtiqueta">
              &#128274; Confirmar Contraseña:
            </label>
            <input
              autoComplete="off"
              type="password"
              name="confirmarPassword"
              placeholder="Confirmar Contraseña"
              className="estiloInput"
              minLength={8}
              value={confirmarP} 
              onChange={(e) => setconfirmarP(e.target.value)}
              required
              
            />
          </div>

          <div className="estiloCampo">
            <label htmlFor="datos_cliente.pregunta" className="estiloEtiqueta">
              Selecciona una pregunta:
            </label>
            <select
              id="datos_cliente.pregunta"
              name="datos_cliente.pregunta._id"
              className="estiloInput"
              value={formData.datos_cliente.pregunta._id}
              onChange={handleChange}
              required
            >
              <option value="">Seleccione una pregunta</option>
              {preguntas.map((pregunta) => (
                <option key={pregunta._id} value={pregunta._id}>
                  {pregunta.pregunta}
                </option>
              ))}
            </select>
          </div>
          <div className="estiloCampo">
            <label
              htmlFor="datos_cliente.pregunta.respuesta"
              className="estiloEtiqueta"
            >
              &#128274; Respuesta:
            </label>
            <input
              autoComplete="off"
              type="text"
              name="respuesta"
              placeholder="respuesta"
              className="estiloInput"
              value={formData.datos_cliente.pregunta.respuesta}
              onChange={handleChange}
              minLength={4}
              maxLength={12}
              required
            />
          </div>

          <br />
          <br />

          <button type="submit" className="estiloBoton">
            Registro
          </button>

          <br />
          {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
        </form>
      </div>
    </>
  );
}

export default FormularioRegistro;
