import React, { useState, useEffect } from 'react';
import axios from 'axios';
import WaterTank from './WaterTank';

const WaterTankContainer = ({ idDispositivo }) => {
  const [waterLevel, setWaterLevel] = useState(0);
  const maxLevel = 100; // Ajusta el máximo nivel de agua según tus necesidades

  useEffect(() => {
    const dispensadoraId = idDispositivo; // Usando el idDispositivo recibido como propiedad

    const interval = setInterval(() => {
      axios.get(`https://backend-render-qavo.onrender.com/api/iot/topic2?id=${dispensadoraId}`)
        .then(response => {
          const { valor } = response.data;
          console.log('Valor recibido del servidor para el nivel de agua:', valor);
          // Ajusta el valor recibido al rango de 0 a 100
          const adjustedValue = (valor / 100) * 100;
          setWaterLevel(adjustedValue);

          // Actualiza solo el campo tanque.agua en la dispensadora
          axios.put(`https://backend-render-qavo.onrender.com/api/dispositivo/${dispensadoraId}`, {
            "tanques.agua": adjustedValue
          })
          .then(response => {
            console.log('Dispensadora actualizada en el servidor:', response.data);
          })
          .catch(error => {
            console.error('Error al actualizar la dispensadora en el servidor:', error);
          });

        })
        .catch(error => {
          console.error('Error al obtener el nivel de agua:', error);
        });
    }, 500); // Realiza la solicitud cada 5 segundos

    return () => clearInterval(interval);
  }, [idDispositivo]); // Asegurándose de que el efecto se vuelva a ejecutar si idDispositivo cambia

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '40px',
      textAlign: 'center',
      borderRadius: '15px',
      maxWidth: '600px',
      margin: '40px auto',
    },
    title: {
      color: '#333',
      marginBottom: '30px',
      fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
      fontWeight: '300',
      fontSize: '18px', // Reducido el tamaño de la fuente para hacer las letras más "cortas".

    },
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Nivel de Agua</h2>
      <WaterTank level={waterLevel} maxLevel={maxLevel} />
    </div>
  );
};

export default WaterTankContainer;
