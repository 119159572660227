import React, { useState, useEffect } from 'react';
import axios from 'axios';
import WaterBowl from './WaterBowl';

const WaterBowlContainer = ({ idDispositivo }) => {
  const [waterLevel, setWaterLevel] = useState(0);
  const maxLevel = 100;

  useEffect(() => {
    console.log('ID del dispositivo:', idDispositivo); // Agregar console.log() aquí para verificar idDispositivo
    const dispensadoraId = idDispositivo;

    const interval = setInterval(() => {
      axios.get(`https://backend-render-qavo.onrender.com/api/iot/topic1?id=${dispensadoraId}`)
        .then(response => {
          const { valor } = response.data;
          console.log('Valor recibido del servidor para el nivel del plato de agua:', valor);
          // Ajusta el valor recibido al rango de 0 a 100
          const adjustedValue = Math.min((valor / 100) * 100, 100);
          setWaterLevel(adjustedValue);

          // Actualiza solo el campo platos.agua en la dispensadora
          axios.put(`https://backend-render-qavo.onrender.com/api/dispositivo/${dispensadoraId}`, {
            "platos.agua": adjustedValue
          })
          .then(response => {
            console.log('Dispensadora actualizada en el servidor:', response.data);
          })
          .catch(error => {
            console.error('Error al actualizar la dispensadora en el servidor:', error);
          });

        })
        .catch(error => {
          console.error('Error al obtener el nivel del plato de agua:', error);
        });
    }, 500);

    return () => clearInterval(interval);
  }, [idDispositivo]);

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '20px',
      textAlign: 'center',
      backgroundColor: '#ffffff',
      borderRadius: '15px',
      maxWidth: '400px',
      margin: '20px auto',
    },
    title: {
      color: '#333',
      marginBottom: '20px',
      fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
      fontWeight: '300',
      fontSize: '18px', // Reducido el tamaño de la fuente para hacer las letras más "cortas".
    },
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Nivel de Agua </h2>
      <WaterBowl level={waterLevel} maxLevel={maxLevel} />
    </div>
  );
};

export default WaterBowlContainer;
