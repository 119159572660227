import React, { useRef, useEffect, useState } from 'react';

const WaterBowl = ({ level, maxLevel }) => {
  const canvasRef = useRef(null);
  const [currentLevel, setCurrentLevel] = useState((level / maxLevel) * 100);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentLevel((prevLevel) => {
        const targetLevel = (level / maxLevel) * 100;
        const delta = targetLevel - prevLevel;
        if (Math.abs(delta) < 0.5) {
          clearInterval(intervalId);
          return targetLevel;
        }
        return prevLevel + delta * 0.05;
      });
    }, 16);

    return () => clearInterval(intervalId);
  }, [level, maxLevel]);

  const drawWaves = (ctx, timestamp) => {
    const width = ctx.canvas.width;
    const height = ctx.canvas.height;
    ctx.clearRect(0, 0, width, height);

    ctx.fillStyle = '#457B9D';
    const waveAmplitude = 3; // Menos altura de las olas para un plato poco profundo
    const waveFrequency = 0.1; // Mayor frecuencia para simular un espacio más confinado
    const waveSpeed = 0.02; // Aumentamos la velocidad para que el agua parezca más dinámica

    ctx.beginPath();
    for (let x = 0; x < width; x++) {
      const y = Math.sin(x * waveFrequency + timestamp * waveSpeed) * waveAmplitude + (height * (1 - currentLevel / 100));
      ctx.lineTo(x, y);
    }
    ctx.lineTo(width, height);
    ctx.lineTo(0, height);
    ctx.closePath();
    ctx.fill();
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    let frameId;

    const render = (timestamp) => {
      drawWaves(context, timestamp);
      frameId = requestAnimationFrame(render);
    };

    frameId = requestAnimationFrame(render);

    return () => cancelAnimationFrame(frameId);
  }, [currentLevel]);

  return (
    <div style={{ width: '150px', height: '150px', position: 'relative', border: '6px solid #457B9D', borderRadius: '50%', overflow: 'hidden' }}>
      <div style={{ width: '130px', height: '130px', backgroundColor: '#A8DADC', borderRadius: '50%', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
      <canvas ref={canvasRef} width="150" height="150" style={{ position: 'absolute', top: '0', left: '0' }} />
      <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white', fontWeight: 'bold' }}>
        {Math.round(currentLevel)}%
      </div>
    </div>
  );
};

export default WaterBowl;
