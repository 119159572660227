import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import axios from 'axios';

const estilos = {
  contenedor: {
    maxWidth: '100%',
    padding: '20px 40px',
  },
  tabla: {
    width: '100%',
    borderCollapse: 'collapse',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    margin: '20px 0',
    fontFamily: '"Segoe UI", Arial, sans-serif',
  },
  cabecera: {
    backgroundColor: '#800080',
    color: 'white',
    padding: '12px 15px',
  },
  celda: {
    border: '1px solid #ddd',
    padding: '10px 15px',
    textAlign: 'left',
    backgroundColor: 'white',
  },
  celdaImpar: {
    backgroundColor: '#f2e5ff',
  },
  titulo: {
    textAlign: 'center',
    color: '#333',
    fontFamily: '"Segoe UI", Arial, sans-serif',
    margin: '20px 0',
    fontSize: '24px',
  },
  botonEliminar: {
    cursor: 'pointer',
    backgroundColor: '#ff0000',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    padding: '5px 10px',
    marginRight: '5px',
  },
  botonEditar: {
    cursor: 'pointer',
    backgroundColor: '#008000',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    padding: '5px 10px',
  },
  input: {
    width: '100%',
    padding: '8px',
    margin: '5px 0 15px 0',
    display: 'inline-block',
    border: '1px solid #ccc',
    borderRadius: '4px',
    boxSizing: 'border-box',
  },
  submitButton: {
    width: '100%',
    backgroundColor: '#4CAF50',
    color: 'white',
    padding: '14px 20px',
    margin: '8px 0',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  cancelButton: {
    width: '100%',
    backgroundColor: '#f44336',
    color: 'white',
    padding: '14px 20px',
    margin: '8px 0',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  }
};

const AsignarIot = () => {
  const navigate = useNavigate();
  const [usuarios, setUsuarios] = useState([]);
  const [selectedUsuario, setSelectedUsuario] = useState('');
  const [dispositivos, setDispositivos] = useState([]);
  const [selectedDispositivo, setSelectedDispositivo] = useState('');
  const userId = localStorage.getItem('userId');

  useEffect(() => {
    fetchUsuarios();
    fetchDispositivosNoAsignados();
  }, []);

  async function fetchUsuarios() {
    try {
      const res = await axios.get('https://backend-render-qavo.onrender.com/api/usuarios');
      setUsuarios(res.data.filter(usuario => usuario.tipo.includes('cliente')));
    } catch (error) {
      console.error("Error al cargar los usuarios:", error);
    }
  }

  async function fetchDispositivosNoAsignados() {
    try {
      const res = await axios.get('https://backend-render-qavo.onrender.com/api/dispositivo/no-asignados');
      setDispositivos(res.data);
    } catch (error) {
      console.error("Error al cargar dispositivos:", error);
    }
  }

  const asignarDispositivo = async () => {
    if (!selectedUsuario || !selectedDispositivo) {
      alert("Por favor, seleccione un usuario y un dispositivo.");
      return;
    }

    try {
      await axios.post(`https://backend-render-qavo.onrender.com/api/usuarios/${selectedUsuario}/asignar-dispositivo`, { codigoDispositivo: selectedDispositivo });
      alert("Dispositivo asignado con éxito.");
      fetchUsuarios(); // Actualizar lista de usuarios
      fetchDispositivosNoAsignados(); // Actualizar lista de dispositivos disponibles
      setSelectedUsuario('');
      setSelectedDispositivo('');
    } catch (error) {
      console.error("Error al asignar dispositivo:", error);
      alert("Hubo un error al asignar el dispositivo.");
    }
  };

  return (
    <div style={estilos.contenedor}>
      <ArrowLeftOutlined onClick={() => navigate(-1)} style={estilos.botonEditar} />
      <h1 style={estilos.titulo}>Asignar IoT a Usuarios</h1>

      <select onChange={(e) => setSelectedUsuario(e.target.value)} value={selectedUsuario} style={estilos.input}>
        <option value="">Seleccione un Usuario</option>
        {usuarios.map((usuario) => (
          <option key={usuario._id} value={usuario._id}>{usuario.username}</option>
        ))}
      </select>

      <select onChange={(e) => setSelectedDispositivo(e.target.value)} value={selectedDispositivo} style={estilos.input}>
        <option value="">Seleccione un Dispositivo</option>
        {dispositivos.map((dispositivo) => (
          <option key={dispositivo._id} value={dispositivo.codigo}>{dispositivo.nombre}</option>
        ))}
      </select>

      <button onClick={asignarDispositivo} style={estilos.submitButton}>Asignar Dispositivo</button>

      <h2 style={estilos.titulo}>Usuarios</h2>
      <table style={estilos.tabla}>
        <thead>
          <tr>
            <th style={estilos.cabecera}>Usuario</th>
            <th style={estilos.cabecera}>Dispositivos Asignados</th>
          </tr>
        </thead>
        <tbody>
          {usuarios.map(usuario => (
            <tr key={usuario._id}>
              <td style={estilos.celda}>{usuario.username}</td>
              <td style={estilos.celda}>
                {usuario.iotDev ? (
                  usuario.iotDev.length > 0 ? (
                    <DispositivosAsignados userId={usuario._id} />
                  ) : (
                    'Ninguno'
                  )
                ) : (
                  'No tiene dispositivos asignados'
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <h2 style={estilos.titulo}>Dispositivos Disponibles</h2>
      <table style={estilos.tabla}>
        <thead>
          <tr>
            <th style={estilos.cabecera}>Dispositivo</th>
          </tr>
        </thead>
        <tbody>
          {dispositivos.map((dispositivo, index) => (
            <tr key={dispositivo._id}>
              <td style={index % 2 === 0 ? estilos.celda : estilos.celdaImpar}>{dispositivo.nombre}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const DispositivosAsignados = ({ userId }) => {
  const [dispositivos, setDispositivos] = useState([]);

  useEffect(() => {
    fetchDispositivosAsignados(userId);
  }, [userId]);

  async function fetchDispositivosAsignados(userId) {
    try {
      const res = await axios.get(`https://backend-render-qavo.onrender.com/api/usuarios/${userId}/dispositivo-asignado`);
      setDispositivos(res.data);
    } catch (error) {
      console.error("Error al cargar dispositivos asignados:", error);
    }
  }

  return (
    <ul>
      {dispositivos.map(dispositivo => (
        <li key={dispositivo._id}>{dispositivo.nombre}</li>
      ))}
    </ul>
  );
};

export default AsignarIot;
