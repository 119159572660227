import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';


const IoTCliente = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  return (
    <div>
       <ArrowLeftOutlined className="back-icones" onClick={goBack} />
       <br></br> <br></br>
      <h1>Control del Dispensador</h1>
      
    </div>
  );
};

export default IoTCliente;
