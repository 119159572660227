import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';

const PreguntasFrecuentesCliente = () => {
  const [faqs, setFaqs] = useState([]);
  const [expandedState, setExpandedState] = useState([]);

  useEffect(() => {
    fetchFaqs();
  }, []);

  const fetchFaqs = async () => {
    try {
      const response = await axios.get('https://backend-render-qavo.onrender.com/api/faqs/faqs');
      if (response.status === 200) {
        const faqsData = response.data;
        const initialExpandedState = faqsData.map(() => false);
        setExpandedState(initialExpandedState);
        setFaqs(faqsData);
      } else {
        console.error('Failed to fetch FAQs');
      }
    } catch (error) {
      console.error('Error fetching FAQs:', error);
    }
  };

  const toggleAccordion = (index) => {
    setExpandedState(prevState =>
      prevState.map((state, idx) => (idx === index ? !state : state))
    );
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Preguntas Frecuentes</h2>
      {faqs.map((faq, index) => (
        <div key={index} style={styles.faqWrapper}>
          <div onClick={() => toggleAccordion(index)} style={styles.faqTouchable}>
            <div style={styles.faqContainer}>
              <p style={styles.faqTitle}>{faq.preguntas}</p>
              <FontAwesomeIcon
                icon={expandedState[index] ? faAngleUp : faAngleDown}
                size="lg"
                color="#FFA500" // Color del ícono
              />
            </div>
          </div>
          {expandedState[index] && (
            <div style={styles.faqDescriptionContainer}>
              <p style={styles.faqDescription}>{faq.respuesta}</p>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

const styles = {
  container: {
    flex: 1,
    backgroundColor: '#fff',
    padding: '20px',
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#FFA500', // Anaranjado para el título
    marginBottom: '20px',
  },
  faqWrapper: {
    marginBottom: '20px',
    borderBottom: '1px solid #FFA500', // Anaranjado para el borde
  },
  faqTouchable: {
    cursor: 'pointer',
    paddingBottom: '10px',
  },
  faqContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  faqTitle: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#FFA500', // Anaranjado para las preguntas
    marginRight: '10px',
  },
  faqDescriptionContainer: {
    marginTop: '10px',
    paddingLeft: '28px',
  },
  faqDescription: {
    fontSize: '16px',
    lineHeight: '24px',
    color: '#800080', // Morado para las respuestas
  },
};

export default PreguntasFrecuentesCliente;
