import React, { useState, useEffect } from 'react';
import axios from 'axios';
import bcrypt from "bcryptjs";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowLeftOutlined } from '@ant-design/icons';
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import "./Styles_Shared/Registro.css";

const MySwal = withReactContent(Swal);

function ActualizarPass() {
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [usuarios, setUsuarios] = useState([]);
  const { id }=useParams();
 
  useEffect(() => {
    axios.get(`https://backend-render-qavo.onrender.com/api/usuarios`)
      .then(response => {
        if (response.status === 200) {
          setUsuarios(response.data);
          setErrorMessage('');
          console.log('Se obtuvieron los usuarios correctamente.');
        } else {
          setErrorMessage('Error al obtener los usuarios desde la base de datos.');
          console.error('Error al obtener los usuarios:', response);
        }
      })
      .catch(error => {
        console.error('Error al obtener los usuarios:', error);
        setErrorMessage('Error al obtener los usuarios desde la base de datos. Por favor, inténtalo de nuevo más tarde.');
      });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();


    if (password !== confirmPassword) {
      setErrorMessage('Las contraseñas no coinciden.');
      return;
    }
   console.log(id);
    const usuarioEncontrado = usuarios.find(usuario => usuario._id===id);

    const hashedPassword = await bcrypt.hash(password, 10);

    if (usuarioEncontrado) {
      const nombreU= usuarioEncontrado.username;
      const updatedData = {
        password: hashedPassword
      };

      try {
        await axios.patch(`https://backend-render-qavo.onrender.com/api/usuarios/${usuarioEncontrado._id}`, updatedData);
        MySwal.fire({
          title: "Tu registro se realizo correctamente",
          text: " " + nombreU + "",
          icon: "success", // Puedes cambiar el icono: "success", "error", "warning", "info"
          confirmButtonText: "Aceptar",
        });

        navigate("/login");
      } 
      catch (error) 
      {
        console.error('Error al actualizar la contraseña:', error);
        setErrorMessage('Error al guardar en la base de datos. Por favor, inténtalo de nuevo más tarde.');
      }
    } else {
      setErrorMessage('El usuario no existe en la base de datos.');
    }
  };
  const goBack = () => {
    navigate(-1);
  };

  
  return (
    <>
      <div className="estiloContenedor-1">
              <ArrowLeftOutlined className="back-icones" onClick={goBack} />
      <Link to="/">
      <br></br>
      <div className="incon_house-c">&#127968;</div>
      </Link>
      <h1 className="estiloTitulo">Cambiar contraseña</h1>
      <form onSubmit={handleSubmit} autoComplete="of">
      <div className="estiloCampo">  
        <label htmlFor="txtcorreo" className="estiloEtiqueta">
        &#128100; Inserte su contraseña:</label>
        <input
         className="estiloInput"
        type="password" 
        value={password} onChange={(e) => setPassword(e.target.value)}
        minLength={8}
        placeholder="Ingrese una contraseña segura"
        pattern="^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
         title="La contraseña debe tener al menos 8 caracteres, incluyendo al menos una letra mayúscula, una letra minúscula, un número y un carácter especial."
         required
         
         />
        </div>
        <div className="estiloCampo"> 
        <label className="estiloEtiqueta"> &#128100; Confirmar contraseña:</label>
        <input className="estiloInput"
        type="password" value={confirmPassword} required onChange={(e) => setConfirmPassword(e.target.value)} />
       </div>
        <br></br>
        <button className="estiloBoton" type="submit">Actualizar</button>
      </form>
      {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
    </div>
    </>
  );
}

export default ActualizarPass;
