import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff } from '@fortawesome/free-solid-svg-icons';

const FoodDispenserControl = () => {
  const [isDispensing, setIsDispensing] = useState(false);

  const sendCommandToServer = () => {
    // Aquí se envía el comando "ON" al servidor
    fetch('https://backend-render-qavo.onrender.com/api/iot/activarMotor', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ mensaje: 'ON' }),
    })
      .then(response => response.json())
      .then(data => {
        console.log('Respuesta del servidor:', data);
        // Cambia el estado a "dispensing" por 1 segundo
        setIsDispensing(true);
        setTimeout(() => {
          setIsDispensing(false);
        }, 2000); // Cambia de nuevo al estado original después de 1 segundo
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const toggleDispensing = () => {
    sendCommandToServer(); // Llama a esta función cuando el botón es presionado
  };

  const buttonStyles = {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'none',
    border: '2px solid',
    borderColor: isDispensing ? '#4CAF50' : '#E76F51',
    borderRadius: '50%',
    outline: 'none',
    cursor: 'pointer',
    fontSize: '4rem',
    color: isDispensing ? '#4CAF50' : '#E76F51',
    width: '100px',
    height: '100px',
    padding: '0',
    transition: 'all 0.3s ease',
    boxShadow: '0 2px 10px rgba(0,0,0,0.2)',
    transform: 'scale(1)',
  };

  return (
    <div style={{ padding: '20px', textAlign: 'center' }}>
      <h2 style={{ fontFamily: 'Arial, sans-serif', color: '#333', fontSize: '18px' }}>Comida</h2>
      <p style={{ fontFamily: 'Arial, sans-serif', color: '#555', margin: '10px 0', fontWeight: 'bold' }}>
        {isDispensing ? 'Dispensando' : 'Detenida'}
      </p>
      <button 
        onClick={toggleDispensing} 
        style={buttonStyles}
        aria-pressed={isDispensing}
      >
        <FontAwesomeIcon icon={faPowerOff} />
      </button>
    </div>
  );
};

export default FoodDispenserControl;
