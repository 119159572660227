import React, { useState, useEffect } from 'react';
import axios from 'axios';

import FoodTank from './FoodTank';

const FoodTankContainer = ({ idDispositivo }) => {
  const [foodLevel, setFoodLevel] = useState(0);
  const maxLevel = 100;

  useEffect(() => {
    const dispensadoraId = idDispositivo; // Usando el idDispositivo recibido como propiedad

    const interval = setInterval(() => {
      axios.get(`https://backend-render-qavo.onrender.com/api/iot/topic3?id=${dispensadoraId}`)
        .then(response => {
          const { valor } = response.data;
          console.log('Valor recibido del servidor:', valor);
          // Ajustar el valor recibido al rango de 0 a 100
          const adjustedValue = (valor / 100) * 100;
          setFoodLevel(adjustedValue);

          // Actualizar solo el campo tanques.comida en la dispensadora
          axios.put(`https://backend-render-qavo.onrender.com/api/dispositivo/${dispensadoraId}`, {
            "tanques.comida": adjustedValue
          })
            .then(response => {
              console.log('Dispensadora actualizada en el servidor:', response.data);
            })
            .catch(error => {
              console.error('Error al actualizar la dispensadora en el servidor:', error);
            });
        })
        .catch(error => {
          console.error('Error al obtener el nivel de comida:', error);
        });
    }, 500);

    return () => clearInterval(interval);
  }, [idDispositivo]); // Asegurándose de que el efecto se vuelva a ejecutar si idDispositivo cambia

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '40px',
      textAlign: 'center',
      borderRadius: '15px',
      maxWidth: '600px',
      margin: '40px auto',
    },
    title: {
      color: '#333',
      marginBottom: '30px',
      fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
      fontWeight: '300',
      fontSize: '18px',
    },
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Nivel Comida</h2>
      <FoodTank level={foodLevel} maxLevel={maxLevel} />
    </div>
  );
};

export default FoodTankContainer;
