import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './ProductosFiltrados.css';
import { ArrowLeftOutlined } from '@ant-design/icons';

const ProductosFiltrados = () => {
  const { categoria } = useParams();
  const navigate = useNavigate();
  const [productos, setProductos] = useState([]);
  const [paginaActual, setPaginaActual] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(0); // Estado para el total de páginas

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://backend-render-qavo.onrender.com/api/productos/categorias/${categoria}?page=${paginaActual}&limit=12`);
        if (!response.ok) {
          throw new Error('Error fetching productos');
        }
        const data = await response.json();
        setProductos(data.productos);
        setTotalPaginas(data.totalPaginas);
      } catch (error) {
        console.error('Error fetching productos:', error);
      }
    };
    fetchData();
  }, [categoria, paginaActual]);

  const handleProductClick = (productoId) => {
    navigate(`/cliente/categorias/${categoria}/${productoId}`);
  };

  // Genera los números de página
  const paginas = [];
  for (let i = 1; i <= totalPaginas; i++) {
    paginas.push(i);
  }

  // Función para retroceder
  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
       <ArrowLeftOutlined className="back-icones" onClick={goBack} />
      <div className="container">
        {productos.map((producto) => (
          <div key={producto._id} className="product" onClick={() => handleProductClick(producto._id)}>
            {producto.fotos && producto.fotos.length > 0 && (
              <img src={producto.fotos[0].url} alt={`Foto de ${producto.nombre}`} className="product-img" />
            )}
            <div className="overlay">
              <h4>Producto: {producto.nombre}</h4>
              <p>Precio: ${producto.precio}</p>
            </div>
          </div>
        ))}
        <div className="pagination">
          {paginas.map(numero => (
            <button
              key={numero}
              onClick={() => setPaginaActual(numero)}
              style={{
                background: paginaActual === numero ? 'purple' : '#ddd',
                color: paginaActual === numero ? 'white' : 'black',
              }}
              className="page-number"
            >
              {numero}
            </button>
          ))}
        </div>
      </div>
    </>
  );
};

export default ProductosFiltrados;



