import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import './Styles_Shared/login.css'
import bcrypt from "bcryptjs";
import { useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons'; // Importa bcrypt para la comparación de contraseñas
import { FaEye, FaEyeSlash } from 'react-icons/fa';

// Creamos una instancia para las alertas
const MySwal = withReactContent(Swal);

function LoginForm() {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [usuarios, setUsuarios] = useState([]);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    // Realizar la solicitud GET para obtener los usuarios
    axios.get('https://backend-render-qavo.onrender.com/api/usuarios')
      .then(response => {
        // Almacenar los datos de los usuarios en el estado
        setUsuarios(response.data);
      })
      .catch(error => console.error("Error al cargar los usuarios: ", error));
  }, []);
    
  const goBack = () => {
    navigate(-1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if(!username.trim() && !password.trim()){
      setErrorMessage("Campos vacios")
      
      return;
    }
    else if(!username.trim()){
      setErrorMessage("Por favor inserte su usuario")
      return; 
    }else if(!password.trim()){
      setErrorMessage("Por favor inserte su contraseña")
    }
    else{
    // Buscar el usuario por nombre de usuario
    const usuarioEncontrado = usuarios.find(usuario => usuario.username === username);

    if (usuarioEncontrado) {
      // Comparar la contraseña utilizando bcrypt
      const isMatch = await bcrypt.compare(password, usuarioEncontrado.password);
      if (isMatch) {
        // Guardar la información del usuario en el almacenamiento local
        localStorage.setItem('usuario', JSON.stringify(usuarioEncontrado));

        // Redirigir según el tipo de usuario y mostrar una notificación de éxito
        let ruta = '/';
        let mensaje = 'Has iniciado sesión correctamente.';
        switch (usuarioEncontrado.tipo[0]) {
          case "cliente":
            ruta = '/cliente';
            break;
          case "administrador":
            ruta = '/admin';
            break;
          default:
            setErrorMessage('Tipo de usuario desconocido');
            return;
        }
        MySwal.fire({
          position: "top-end",
          icon: "success",
          title: mensaje,
          showConfirmButton: false,
          timer: 4500
        }).then(() => {
          window.location.href = ruta;
        });
      } else {
        setErrorMessage('Contraseña incorrecta');
      }
    } else {
      setErrorMessage('Usuario no encontrado');
    }
  }
  
}

  // Estilos
  const estiloContenedor = {
    textAlign: 'center',
    backgroundColor: '#e0e0e0',
    padding: '20px',
    borderRadius: '5%',
    maxWidth: '350px',
    margin: 'auto',
    marginTop: '30px',
    marginBottom: '50px',
    borderRadius: '20%',
  };

  const estiloTitulo = {
    fontSize: '24px',
    marginBottom: '20px',
    color: '#333',
  };

  const estiloCampo = {
    marginBottom: '15px',
    textAlign: 'center',
  };

  const estiloEtiqueta = {
    display: 'block',
    marginBottom: '5px',
    textAlign: 'left',
    fontWeight: 'bold',
    color: '#555',
  };

  const estiloInput = {
    width: '100%',
    padding: '10px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    boxSizing: 'border-box',
    fontSize: '16px',
  };



  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  return (
    <div>
      
      <ArrowLeftOutlined className="back-icones" onClick={goBack} />
      <div style={estiloContenedor}>
        <h1 style={estiloTitulo}>Login</h1>
        <form onSubmit={handleSubmit}>
          <div style={estiloCampo}>
            <label style={estiloEtiqueta}>&#128100; Usuario</label>
            <input 
              type="text" 
              placeholder="Usuario" 
              maxLength={15} // Establece el máximo de 10 caracteres
              style={estiloInput} 
              value={username} 
              onChange={(e) => setUsername(e.target.value)}
              required 
            />
          </div>
          <div style={estiloCampo}>
            <label style={estiloEtiqueta}>&#128274; Contraseña</label>
            <input 
             type={showPassword ? 'text' : 'password'}
              placeholder="Contraseña" 
              style={estiloInput} 
              maxLength={15} 
              value={password} 
              onChange={(e) => setPassword(e.target.value)}
              required 
            />
             <button  className='incono-vista' type="button" onClick={togglePasswordVisibility}>
              {showPassword ? <FaEyeSlash /> : <FaEye />}
             </button>
          </div><br></br>
          <a href="#!"  className='btn-1' onClick={handleSubmit}>Iniciar Sesión</a>
          <br /><br></br>
          {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
          
          <Link to="/menuPassword" style={{...estiloCampo, textDecoration: 'none', color: 'blue'}}>¿Olvidaste la contraseña?</Link>
          <br />
          <Link to="/registro" style={{...estiloCampo, textDecoration: 'none', color: 'blue'}}>Regístrate</Link>
        </form>
        
      </div>
    </div>
  );
}

export default LoginForm;
