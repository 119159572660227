import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';

const styles = {
  container: {
    background: 'linear-gradient(135deg, #72c2ff, #004e92)',
    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
    color: '#333',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 0,
  },
  controlarIoTContainer: {
    width: '100%',
    maxWidth: '600px',
    backgroundColor: '#fff',
    padding: '30px',
    borderRadius: '15px',
    boxShadow: '0 8px 20px rgba(0, 0, 0, 0.12)',
    border: '1px solid #E0E0E0',
  },
  titulo: {
    color: '#333',
    marginBottom: '30px',
    textAlign: 'center',
    fontSize: '28px',
    fontWeight: 600,
  },
  dispositivoNoAsignado: {
    color: '#d9534f',
    textAlign: 'center',
    fontSize: '16px',
  },
  botonAlta: {
    backgroundColor: '#9C27B0',
    color: '#fff',
    padding: '12px 20px',
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
    display: 'block',
    width: '100%',
    marginTop: '20px',
    fontSize: '16px',
    fontWeight: 500,
    transition: 'transform 0.2s ease-in-out, background-color 0.2s ease-in-out',
  },
  botonAltaHover: {
    backgroundColor: '#811287',
    transform: 'translateY(-2px)',
  },
  botonCancelar: {
    backgroundColor: '#F44336',
    color: '#fff',
  },
  botonCancelarHover: {
    backgroundColor: '#D32F2F',
    transform: 'translateY(-2px)',
  },
  formulario: {
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    marginBottom: '10px',
    fontSize: '16px',
    color: '#555',
  },
  input: {
    padding: '12px',
    marginBottom: '20px',
    border: '1px solid #ccc',
    borderRadius: '8px',
    boxShadow: 'inset 0 2px 4px rgba(0,0,0,0.08)',
  },
  botonAsignar: {
    backgroundColor: '#2196F3',
    color: '#fff',
    padding: '12px 20px',
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
    width: '100%',
    fontSize: '16px',
    fontWeight: 500,
    transition: 'transform 0.2s ease-in-out, background-color 0.2s ease-in-out',
  },
  botonAsignarHover: {
    backgroundColor: '#0D47A1',
    transform: 'translateY(-2px)',
  },
  reactSelectControl: {
    padding: '12px',
    marginBottom: '20px',
    border: '1px solid #ccc',
    borderRadius: '8px',
    boxShadow: 'inset 0 2px 4px rgba(0,0,0,0.08)',
    borderColor: '#BDBDBD',
    transition: 'border-color 0.2s ease-in-out',
  },
  reactSelectControlHover: {
    borderColor: '#9E9E9E',
  },
  reactSelectMenu: {
    zIndex: 5,
  },
  reactSelectOptionFocused: {
    backgroundColor: '#F5F5F5',
  },
  reactSelectOptionSelected: {
    backgroundColor: '#9C27B0',
    color: 'white',
  },
  mensajeError: {
    color: '#d9534f',
    textAlign: 'center',
    fontSize: '16px',
  },
};

const ControlarIoT = () => {
  const navigate = useNavigate();
  const [codigo, setCodigo] = useState('');
  const [mostrarFormulario, setMostrarFormulario] = useState(false);
  const [usuario, setUsuario] = useState(null);
  const [dispositivosAsignados, setDispositivosAsignados] = useState([]);
  const [error, setError] = useState('');
  const [dispositivoSeleccionado, setDispositivoSeleccionado] = useState(null);

  useEffect(() => {
    const usuarioLocalStorage = JSON.parse(localStorage.getItem('usuario'));
    if (usuarioLocalStorage && usuarioLocalStorage._id) {
      setUsuario(usuarioLocalStorage);
      const verificarDispositivosAsignados = async () => {
        try {
          const response = await axios.get(`https://backend-render-qavo.onrender.com/api/usuarios/${usuarioLocalStorage._id}/dispositivo-asignado`);
          setDispositivosAsignados(response.data || []);
        } catch (error) {
          console.error("Error al verificar los dispositivos asignados:", error);
          setError('No se pudo verificar los dispositivos asignados.');
        }
      };
      verificarDispositivosAsignados();
    }
  }, []);

  const handleAsignarDispositivo = async () => {
    if (!usuario || !codigo) {
      setError('Es necesario estar logueado y proporcionar un código de dispositivo.');
      return;
    }
    try {
      await axios.post(`https://backend-render-qavo.onrender.com/api/usuarios/${usuario._id}/asignar-dispositivo`, { codigoDispositivo: codigo });
      setCodigo('');
      setError('');
      setMostrarFormulario(false);
      // Actualizar la lista de dispositivos asignados
      const response = await axios.get(`https://backend-render-qavo.onrender.com/api/usuarios/${usuario._id}/dispositivo-asignado`);
      setDispositivosAsignados(response.data);
    } catch (error) {
      setError(error.response?.data?.mensaje || 'Error al asignar el dispositivo.');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleAsignarDispositivo();
  };

  const handleControlarDispositivo = () => {
    if (dispositivoSeleccionado) {
      navigate(`/cliente/control-dispositivo/${dispositivoSeleccionado.value}`);
    }
  };

  const handleSeleccionarDispositivo = (dispositivo) => {
    setDispositivoSeleccionado(dispositivo);
  };

  const opcionesDispositivos = dispositivosAsignados.map(dispositivo => ({
    value: dispositivo._id,
    label: dispositivo.nombre
  }));

  return (
    <div style={styles.container}>
      <div style={styles.controlarIoTContainer}>
        <h2 style={styles.titulo}>Controlar IoT</h2>
        {dispositivosAsignados.length > 0 ? (
          <>
            <Select
              options={opcionesDispositivos}
              classNamePrefix="react-select"
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  ...styles.reactSelectControl,
                  ...(state.isFocused && styles.reactSelectControlHover),
                }),
                menu: (provided) => ({
                  ...provided,
                  ...styles.reactSelectMenu,
                }),
                option: (provided, state) => ({
                  ...provided,
                  ...(state.isSelected && styles.reactSelectOptionSelected),
                  ...(state.isFocused && styles.reactSelectOptionFocused),
                }),
              }}
              onChange={handleSeleccionarDispositivo}
            />
            <button onClick={handleControlarDispositivo} style={{ ...styles.botonAlta, ...(dispositivoSeleccionado ? {} : { pointerEvents: 'none', opacity: 0.5 }) }}>
              Controlar Dispositivo
            </button>
          </>
        ) : (
          <p style={styles.dispositivoNoAsignado}>No tienes dispositivos asignados.</p>
        )}
        <button
          style={mostrarFormulario ? { ...styles.botonCancelar, ...styles.botonAltaHover } : styles.botonAlta}
          onClick={() => setMostrarFormulario(!mostrarFormulario)}
        >
          {mostrarFormulario ? 'Cancelar' : 'Asignar nuevo dispositivo'}
        </button>
        {mostrarFormulario && (
          <form onSubmit={handleSubmit} style={styles.formulario}>
            <label htmlFor="codigo" style={styles.label}>Código del dispositivo:</label>
            <input
              type="text"
              id="codigo"
              value={codigo}
              onChange={(e) => setCodigo(e.target.value)}
              style={styles.input}
            />
            <button type="submit" className="boton-asignar" style={styles.botonAsignar}>Confirmar asignación</button>
          </form>
        )}
        {error && <p style={styles.mensajeError}>{error}</p>}
      </div>
    </div>
  );
};

export default ControlarIoT;