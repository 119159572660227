import React, { useState, useEffect } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowLeftOutlined } from '@ant-design/icons';
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import "./Styles_Shared/Registro.css";

//api key
var apiKey ="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";
//var apiKey="sdsdsdsdd"
function ValidarCorreo() {
  const uuid = uuidv4(); // Genera un UUID

  const navigate = useNavigate();

  const [correo, setCorreo] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [usuarios, setUsuarios] = useState([]);
  const { menu } = useParams();
  const [formData, setFormData] = useState({
    Token: "",
    expira: " ",
  });

  useEffect(() => {
    axios
      .get(`https://backend-render-qavo.onrender.com/api/usuarios`)
      .then((response) => {
        // Verificar si la solicitud fue exitosa
        if (response.status === 200) {
          // Aquí debes manejar la respuesta de acuerdo a tus necesidades
          // Por ejemplo, podrías establecer el estado del correo con la respuesta de la API
          setUsuarios(response.data);
          setErrorMessage("");
          console.log("Se obtuvieron los usuarios correctamente.");
        } else {
          // Si la solicitud no fue exitosa, maneja el error adecuadamente
          setErrorMessage(
            "Error al obtener los usuarios desde la base de datos."
          );
          console.error("Error al obtener los usuarios:", response);
        }
      })
      .catch((error) => {
        // Si ocurre un error al realizar la solicitud, maneja el error adecuadamente
        console.error("Error al obtener los usuarios:", error);
        setErrorMessage(
          "Error al obtener los usuarios desde la base de datos. Por favor, inténtalo de nuevo más tarde."
        );
      });
  }, []);



  const handleSubmit = async (e) => {
    e.preventDefault();

    const usuarioEncontrado = usuarios.find( (usuario) => usuario.correo === correo );

    if (usuarioEncontrado) 
    {
       var nombreG = usuarioEncontrado ? usuarioEncontrado.username : "";
       var correoG = usuarioEncontrado ? usuarioEncontrado.correo : "";
       var _idG = usuarioEncontrado ? usuarioEncontrado._id : "";
       //ponemos el token de 6 caracteres
      const shortUUID = uuid.substring(0, 6);

      // Actualizar solo los campos necesarios
      const updatedData = {
        Token: shortUUID,
        expira: Date.now() + 360,
      };

      // Guardar los cambios en la base de datos
      try {
        // Guardar los cambios en la base de datos utilizando Mongoose
        await axios.patch(
          `https://backend-render-qavo.onrender.com/api/usuarios/${usuarioEncontrado._id}`,
          updatedData
        );

        console.log(shortUUID);
        //formato de email
        const emailData = {
          sender: {
            name: "AmiMascota",
            email: "amimascota2024@gmail.com",
          },
          to: [
            {
              email: correo,
              name: "@amimascota.com",
            },
          ],
          subject: "Codigo de verificacion",
          htmlContent: `
            <html>
              <head></head>
              <body>
                
                <p>Este es tu codigo de verificacion: ${shortUUID}</p>
                <p>Emprese de calidad</p>
              </body>
            </html>
          `,
        };

        //hacemos la peticion al api
        try {
          const response = await axios.post(
            "https://api.brevo.com/v3/smtp/email",
            emailData,
            {
              headers: {
                accept: "application/json",
                "api-key": apiKey,
                "content-type": "application/json",
              },
            }
          );
             //alert de enviado correctamente 
             const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.onmouseenter = Swal.stopTimer;
                toast.onmouseleave = Swal.resumeTimer;
              }
            });
            Toast.fire({
              icon: "success",
              title: "Se esta enviando el token al correo: "+ correoG
            });

            const menuNumero= parseInt(menu);




           //validamos el menu
           if(menuNumero===1){
            navigate(`/confirmarToken/${1}` );    
           

           }else if(menuNumero===2){
            navigate(`/confirmarToken/${2}` );    

           }

                 
        } 
        catch (error) {
          console.error("Error:", error);
          setErrorMessage("Error al procesar la solicitud del api.");
        }

      } catch (error) 
      {
        console.error("Error:", error);
        setErrorMessage("Error al generar el token en la  base de datos");
      }

    }else{
      setErrorMessage("Correo no esta registrado.");
    }
  };

  const goBack = () => {
    navigate(-1);
  };


  return (
    <>
      <div className="estiloContenedor-1">
              <ArrowLeftOutlined className="back-icones" onClick={goBack} />
      <Link to="/">
      <br></br>
      <div className="incon_house-c">&#127968;</div>
      </Link>
      <h1 className="estiloTitulo">Recuperar contraseña</h1>
      <form onSubmit={handleSubmit} autoComplete="of">
        <div className="estiloCampo">  
        <label htmlFor="txtcorreo" className="estiloEtiqueta">
            &#128100; Correo
          </label>    
          <input className="estiloInput"
          type="email"
          value={correo}
          placeholder="Inserte correo"
          pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$" // Expresión regular para validar correo electrónico
          onChange={(e) => setCorreo(e.target.value)}
          required
        />
        </div>
        <br></br>
        <button  className="estiloBoton" type="submit">Validar Correo</button>
       
      </form>
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
   
    
    </div>
    </>
    
  );
}

export default ValidarCorreo;
