import React, { useState, useEffect } from 'react';
import './PoliticasPrivacidadCliente.css';
const ContactoCliente = () => {
  const [datosEmpresa, setDatosEmpresa] = useState({  
    redesSociales: {
      facebook: "",
      twitter: "",
      instagram: ""
    },
    telefonoContacto: "",
    emailContacto: "",
    direccion: ""
  });

  useEffect(() => {
    fetch('https://backend-render-qavo.onrender.com/api/datosEmpresa')
      .then(response => {
        if (!response.ok) {
          throw new Error(`Error fetching datosEmpresa: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        console.log('Datos de datosEmpresa:', data);
        if (data.length > 0) {
          setDatosEmpresa(data[0]); 
        }
      })
      .catch(error => {
        console.error('Error fetching datosEmpresa:', error);
      });
  }, []);

  return (
    <div className="container">
      <div className="info-box">
        <h2 className="organization-name"></h2>
        <h3>Redes Sociales</h3>
        <p>Facebook: {datosEmpresa.redesSociales.facebook}</p>
        <p>Twitter: {datosEmpresa.redesSociales.twitter}</p>
        <p>Instagram: {datosEmpresa.redesSociales.instagram}</p>
        <h3>Telefono</h3>
        <p>{datosEmpresa.telefonoContacto}</p>
        <h3>Correo electronico:</h3>
        <p>{datosEmpresa.emailContacto}</p>
        <h3>Ubicacion</h3>
        <p>{datosEmpresa.direccion}</p>
      </div>
    </div>
  );
};
export default ContactoCliente;

