import React, { useState, useEffect } from 'react';
import { Layout, Menu, Typography } from 'antd';
import {
  FacebookOutlined,
  TwitterOutlined,
  InstagramOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';

const { Footer } = Layout;
const { Text } = Typography;

const PieDePaginaCliente = () => {
  const [datosEmpresa, setDatosEmpresa] = useState({
    redesSociales: {
      facebook: "",
      twitter: "",
      instagram: ""
    },
    telefonoContacto: "",
    emailContacto: "",
    direccion: ""
  });

  useEffect(() => {
    fetch('https://backend-render-qavo.onrender.com/api/datosEmpresa')
      .then(response => {
        if (!response.ok) {
          throw new Error(`Error fetching datosEmpresa: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        console.log('Datos de datosEmpresa:', data);
        if (data.length > 0) {
          setDatosEmpresa(data[0]);
        }
      })
      .catch(error => {
        console.error('Error fetching datosEmpresa:', error);
      });
  }, []);


  return (
<Layout>
    <Footer style={{ backgroundColor: '#d45d00', textAlign: 'center', padding: '40px 20px', maxWidth: '100%', boxSizing: 'border-box', display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }}>
        <div style={{ marginBottom: '20px' }}>
          <br></br>
            <h2>Siguenos en nuestras redes sociales</h2>
            <a href={datosEmpresa.redesSociales.facebook} style={{ color: '#ffffff', fontSize: '18px', display: 'block', marginBottom: '10px' }} target="_blank" rel="noopener noreferrer"><FacebookOutlined style={{ fontSize: '20px', color: '#ffffff', marginRight: '5px' }} /> Facebook</a>
            <a href={datosEmpresa.redesSociales.twitter} style={{ color: '#ffffff', fontSize: '18px', display: 'block', marginBottom: '10px' }} target="_blank" rel="noopener noreferrer"><TwitterOutlined style={{ fontSize: '20px', color: '#ffffff', marginRight: '30px' }} /> Twitter</a>
            <a href={datosEmpresa.redesSociales.instagram} style={{ color: '#ffffff', fontSize: '18px', display: 'block' }} target="_blank" rel="noopener noreferrer"><InstagramOutlined style={{ fontSize: '20px', color: '#ffffff', marginRight: '5px' }} /> Instagram</a>
        </div>
        <div style={{ marginTop: '20px' }}>
            <h2>Atencion al cliente</h2>
            <p style={{ color: '#ffffff', fontSize: '18px', marginBottom: '10px' }}>Telefono: {datosEmpresa.telefonoContacto}</p>
            <p style={{ color: '#ffffff', fontSize: '18px', marginBottom: '10px' }}>Correo electrónico: {datosEmpresa.emailContacto}</p>
            <p style={{ color: '#ffffff', fontSize: '18px', marginBottom: '10px' }}>Ubicación: {datosEmpresa.direccion}</p>
        </div>
        <div style={{ marginTop: '20px' }}>
            <h2>Datos de la empresa</h2>
            <Link to="/admin/empresa" style={{ color: '#ffffff', marginRight: '15px', fontSize: '18px', display: 'block', marginBottom: '10px' }}>¿Quiénes Somos?</Link>
            <Link to="/admin/privacidad" style={{ color: '#ffffff', marginRight: '15px', fontSize: '18px', display: 'block' }}>Política de Privacidad</Link><br></br>
            <Link to="/admin/preguntasFrecuentes" style={{ color: '#ffffff', marginRight: '15px', fontSize: '18px', display: 'block' }}>Preguntas frecuentes</Link>
        </div>
    </Footer>
    <div style={{ marginTop: '1px', backgroundColor: '#582c83', textAlign: 'center', padding: '30px 20px', maxWidth: '100%', boxSizing: 'border-box' }}>
        <Text style={{ backgroundColor: '', color: '#ffffff', fontSize: '18px' }}>&copy; {new Date().getFullYear()} Accesorios para mascotas. Todos los derechos reservados.</Text>
    </div>
</Layout>
  );
};

export default PieDePaginaCliente;