import React, { useState, useEffect } from 'react';
import { FaUser, FaEnvelope, FaPhone, FaMapMarkedAlt, FaChevronDown, FaChevronUp, FaEdit, FaSave, FaLock } from 'react-icons/fa';

const SeccionDesplegable = ({ titulo, children }) => {
  const [abierto, setAbierto] = useState(false);

  return (
    <div style={styles.seccion}>
      <div style={styles.tituloSeccion} onClick={() => setAbierto(!abierto)}>
        {titulo}
        {abierto ? <FaChevronUp /> : <FaChevronDown />}
      </div>
      {abierto && <div style={styles.contenidoSeccion}>{children}</div>}
    </div>
  );
};

const PerfilUsuario = () => {
  const [usuario, setUsuario] = useState(JSON.parse(localStorage.getItem('usuario')) || {});
  const [editandoNombreCliente, setEditandoNombreCliente] = useState(false);
  const [nombreCliente, setNombreCliente] = useState('');
  const [editandoUsername, setEditandoUsername] = useState(false);
  const [username, setUsername] = useState('');
  const [editandoApellidoPaterno, setEditandoApellidoPaterno] = useState(false);
  const [apellidoPaterno, setApellidoPaterno] = useState('');
  const [editandoApellidoMaterno, setEditandoApellidoMaterno] = useState(false);
  const [apellidoMaterno, setApellidoMaterno] = useState('');
  const [editandoCorreo, setEditandoCorreo] = useState(false);
  const [correo, setCorreo] = useState('');
  const [editandoTelefono, setEditandoTelefono] = useState(false);
  const [telefono, setTelefono] = useState('');
  const [editandoCalle, setEditandoCalle] = useState(false);
  const [calle, setCalle] = useState('');
  const [editandoNumero, setEditandoNumero] = useState(false);
  const [numero, setNumero] = useState('');
  const [editandoColonia, setEditandoColonia] = useState(false);
  const [colonia, setColonia] = useState('');
  const [editandoLocalidad, setEditandoLocalidad] = useState(false);
  const [localidad, setLocalidad] = useState('');
  const [cambiarContraseña, setCambiarContraseña] = useState(false);
  const [contraseñaActual, setContraseñaActual] = useState('');
  const [nuevaContraseña, setNuevaContraseña] = useState('');
  const [confirmarNuevaContraseña, setConfirmarNuevaContraseña] = useState('');



  useEffect(() => {
    if (usuario) {
      setNombreCliente(usuario.datos_cliente ? usuario.datos_cliente.nombre : '');
      setUsername(usuario.username || '');
      setApellidoPaterno(usuario.datos_cliente ? usuario.datos_cliente.apellidoPaterno : '');
      setApellidoMaterno(usuario.datos_cliente ? usuario.datos_cliente.apellidoMaterno : '');
      setCorreo(usuario.correo || '');
      setTelefono(usuario.datos_cliente ? usuario.datos_cliente.telefono : '');
      setCalle(usuario.datos_cliente ? usuario.datos_cliente.direccion.calle : '');
      setNumero(usuario.datos_cliente ? usuario.datos_cliente.direccion.numero : '');
      setColonia(usuario.datos_cliente ? usuario.datos_cliente.direccion.colonia.nombre : '');
      setLocalidad(usuario.datos_cliente ? usuario.datos_cliente.direccion.colonia.localidad.nombre : '');
    }
  }, [usuario]);

  const handleContraseñaActualChange = (e) => {
    setContraseñaActual(e.target.value);
  };

  const handleNuevaContraseñaChange = (e) => {
    setNuevaContraseña(e.target.value);
  };

  const handleConfirmarNuevaContraseñaChange = (e) => {
    setConfirmarNuevaContraseña(e.target.value);
  };

  const handleNombreClienteChange = (e) => {
    setNombreCliente(e.target.value);
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handleApellidoPaternoChange = (e) => {
    setApellidoPaterno(e.target.value);
  };

  const handleApellidoMaternoChange = (e) => {
    setApellidoMaterno(e.target.value);
  };

  const handleCorreoChange = (e) => {
    setCorreo(e.target.value);
  };

  const handleTelefonoChange = (e) => {
    setTelefono(e.target.value);
  };

  const handleCalleChange = (e) => {
    setCalle(e.target.value);
  };

  const handleNumeroChange = (e) => {
    setNumero(e.target.value);
  };

  const handleColoniaChange = (e) => {
    setColonia(e.target.value);
  };

  const handleLocalidadChange = (e) => {
    setLocalidad(e.target.value);
  };

  const actualizarContraseña = async () => {
    if (nuevaContraseña !== confirmarNuevaContraseña) {
      alert('La nueva contraseña y la confirmación no coinciden.');
      return;
    }

    const urlBase = 'https://backend-render-qavo.onrender.com';
    const idUsuario = usuario._id;
    const url = `${urlBase}/api/usuarios/${idUsuario}/cambiar-contrasena`;

    try {
      const respuesta = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          contraseñaActual: contraseñaActual,
          nuevaContraseña: nuevaContraseña,
        }),
      });

      const datos = await respuesta.json();

      if (respuesta.ok) {
        alert('Contraseña actualizada correctamente.');
        setContraseñaActual('');
        setNuevaContraseña('');
        setConfirmarNuevaContraseña('');
      } else {
        alert(datos.mensaje || 'Error al actualizar la contraseña.');
      }
    } catch (error) {
      console.error('Error al procesar la solicitud:', error);
      alert('Error al conectar con el servidor.');
    }
  };



  const guardarNombreCliente = async () => {
    const urlBase = 'https://backend-render-qavo.onrender.com';
    const idUsuario = usuario._id;
    const url = `${urlBase}/api/usuarios/${idUsuario}`;

    try {
      const respuesta = await fetch(url, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 'datos_cliente.nombre': nombreCliente }),
      });

      if (respuesta.ok) {
        const usuarioActualizado = { ...usuario, datos_cliente: { ...usuario.datos_cliente, nombre: nombreCliente } };
        localStorage.setItem('usuario', JSON.stringify(usuarioActualizado));
        setUsuario(usuarioActualizado);
        setEditandoNombreCliente(false);
      } else {
        console.error('Error al guardar el nombre del cliente');
      }
    } catch (error) {
      console.error('Error al procesar la solicitud:', error);
    }
  };

  const guardarUsername = async () => {
    const urlBase = 'https://backend-render-qavo.onrender.com';
    const idUsuario = usuario._id;
    const url = `${urlBase}/api/usuarios/${idUsuario}`;

    try {
      const respuesta = await fetch(url, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username: username }),
      });

      if (respuesta.ok) {
        const usuarioActualizado = { ...usuario, username: username };
        localStorage.setItem('usuario', JSON.stringify(usuarioActualizado));
        setUsuario(usuarioActualizado);
        setEditandoUsername(false);
      } else {
        console.error('Error al guardar el nombre de usuario');
      }
    } catch (error) {
      console.error('Error al procesar la solicitud:', error);
    }
  };

  const guardarApellidoPaterno = async () => {
    const urlBase = 'https://backend-render-qavo.onrender.com';
    const idUsuario = usuario._id;
    const url = `${urlBase}/api/usuarios/${idUsuario}`;

    try {
      const respuesta = await fetch(url, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 'datos_cliente.apellidoPaterno': apellidoPaterno }),
      });

      if (respuesta.ok) {
        const usuarioActualizado = { ...usuario, datos_cliente: { ...usuario.datos_cliente, apellidoPaterno: apellidoPaterno } };
        localStorage.setItem('usuario', JSON.stringify(usuarioActualizado));
        setUsuario(usuarioActualizado);
        setEditandoApellidoPaterno(false);
      } else {
        console.error('Error al guardar el apellido paterno');
      }
    } catch (error) {
      console.error('Error al procesar la solicitud:', error);
    }
  };

  const guardarApellidoMaterno = async () => {
    const urlBase = 'https://backend-render-qavo.onrender.com';
    const idUsuario = usuario._id;
    const url = `${urlBase}/api/usuarios/${idUsuario}`;

    try {
      const respuesta = await fetch(url, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 'datos_cliente.apellidoMaterno': apellidoMaterno }),
      });

      if (respuesta.ok) {
        const usuarioActualizado = { ...usuario, datos_cliente: { ...usuario.datos_cliente, apellidoMaterno: apellidoMaterno } };
        localStorage.setItem('usuario', JSON.stringify(usuarioActualizado));
        setUsuario(usuarioActualizado);
        setEditandoApellidoMaterno(false);
      } else {
        console.error('Error al guardar el apellido materno');
      }
    } catch (error) {
      console.error('Error al procesar la solicitud:', error);
    }
  };

  const guardarCorreo = async () => {
    const urlBase = 'https://backend-render-qavo.onrender.com';
    const idUsuario = usuario._id;
    const url = `${urlBase}/api/usuarios/${idUsuario}`;

    try {
      const respuesta = await fetch(url, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ correo: correo }),
      });

      if (respuesta.ok) {
        const usuarioActualizado = { ...usuario, correo: correo };
        localStorage.setItem('usuario', JSON.stringify(usuarioActualizado));
        setUsuario(usuarioActualizado);
        setEditandoCorreo(false);
      } else {
        console.error('Error al guardar el correo electrónico');
      }
    } catch (error) {
      console.error('Error al procesar la solicitud:', error);
    }
  };

  const guardarTelefono = async () => {
    const urlBase = 'https://backend-render-qavo.onrender.com';
    const idUsuario = usuario._id;
    const url = `${urlBase}/api/usuarios/${idUsuario}`;

    try {
      const respuesta = await fetch(url, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 'datos_cliente.telefono': telefono }),
      });

      if (respuesta.ok) {
        const usuarioActualizado = { ...usuario, datos_cliente: { ...usuario.datos_cliente, telefono: telefono } };
        localStorage.setItem('usuario', JSON.stringify(usuarioActualizado));
        setUsuario(usuarioActualizado);
        setEditandoTelefono(false);
      } else {
        console.error('Error al guardar el teléfono');
      }
    } catch (error) {
      console.error('Error al procesar la solicitud:', error);
    }
  };

  const guardarDireccion = async () => {
    const urlBase = 'https://backend-render-qavo.onrender.com';
    const idUsuario = usuario._id;
    const url = `${urlBase}/api/usuarios/${idUsuario}`;

    try {
      const respuesta = await fetch(url, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          'datos_cliente.direccion.calle': calle,
          'datos_cliente.direccion.numero': numero,
          'datos_cliente.direccion.colonia.nombre': colonia,
          'datos_cliente.direccion.colonia.localidad.nombre': localidad,
        }),
      });

      if (respuesta.ok) {
        const usuarioActualizado = {
          ...usuario,
          datos_cliente: {
            ...usuario.datos_cliente,
            direccion: {
              ...usuario.datos_cliente.direccion,
              calle: calle,
              numero: numero,
              colonia: {
                ...usuario.datos_cliente.direccion.colonia,
                nombre: colonia,
                localidad: {
                  ...usuario.datos_cliente.direccion.colonia.localidad,
                  nombre: localidad,
                },
              },
            },
          },
        };
        localStorage.setItem('usuario', JSON.stringify(usuarioActualizado));
        setUsuario(usuarioActualizado);
        setEditandoCalle(false);
        setEditandoNumero(false);
        setEditandoColonia(false);
        setEditandoLocalidad(false);
      } else {
        console.error('Error al guardar la dirección');
      }
    } catch (error) {
      console.error('Error al procesar la solicitud:', error);
    }
  };

  return (
    <>
      <h1 style={styles.perfilTitle}>Perfil de Usuario</h1>
      {usuario && (
        <div style={styles.perfilCard}>
          <div style={styles.avatarContainer}>
            <img src="../logoMascota.png" alt="Avatar" style={styles.avatar} />
            {editandoUsername ? (
              <div>
                <input type="text" value={username} onChange={handleUsernameChange} style={styles.input} />
                <button onClick={guardarUsername} style={styles.button}><FaSave /></button>
              </div>
            ) : (
              <p style={styles.nombreUsuario}><FaUser /> {usuario.username} <button onClick={() => setEditandoUsername(true)} style={styles.buttonEdit}><FaEdit /></button></p>
            )}
          </div>
          <SeccionDesplegable titulo="Información Personal">
            <div style={styles.contenidoSeccion}>
              {editandoNombreCliente ? (
                <div>
                  <label style={styles.label}>Nombre:</label>
                  <input type="text" value={nombreCliente} onChange={handleNombreClienteChange} style={styles.input} />
                  <button onClick={guardarNombreCliente} style={styles.button}><FaSave /></button>
                </div>
              ) : (
                <p><strong>Nombre:</strong> {usuario.datos_cliente ? usuario.datos_cliente.nombre : ''} <button onClick={() => setEditandoNombreCliente(true)} style={styles.buttonEdit}><FaEdit /></button></p>
              )}
              {editandoApellidoPaterno ? (
                <div>
                  <label style={styles.label}>Apellido Paterno:</label>
                  <input type="text" value={apellidoPaterno} onChange={handleApellidoPaternoChange} style={styles.input} />
                  <button onClick={guardarApellidoPaterno} style={styles.button}><FaSave /></button>
                </div>
              ) : (
                <p><strong>Apellido Paterno:</strong> {usuario.datos_cliente ? usuario.datos_cliente.apellidoPaterno : ''} <button onClick={() => setEditandoApellidoPaterno(true)} style={styles.buttonEdit}><FaEdit /></button></p>
              )}
              {editandoApellidoMaterno ? (
                <div>
                  <label style={styles.label}>Apellido Materno:</label>
                  <input type="text" value={apellidoMaterno} onChange={handleApellidoMaternoChange} style={styles.input} />
                  <button onClick={guardarApellidoMaterno} style={styles.button}><FaSave /></button>
                </div>
              ) : (
                <p><strong>Apellido Materno:</strong> {usuario.datos_cliente ? usuario.datos_cliente.apellidoMaterno : ''} <button onClick={() => setEditandoApellidoMaterno(true)} style={styles.buttonEdit}><FaEdit /></button></p>
              )}
            </div>
          </SeccionDesplegable>
          <SeccionDesplegable titulo="Contactos">
            <div style={styles.contenidoSeccion}>
              {editandoCorreo ? (
                <div>
                  <label style={styles.label}>Correo electrónico:</label>
                  <input type="text" value={correo} onChange={handleCorreoChange} style={styles.input} />
                  <button onClick={guardarCorreo} style={styles.button}><FaSave /></button>
                </div>
              ) : (
                <p><FaEnvelope /> <strong>Correo electrónico:</strong> {usuario.correo || ''} <button onClick={() => setEditandoCorreo(true)} style={styles.buttonEdit}><FaEdit /></button></p>
              )}
              {editandoTelefono ? (
                <div>
                  <label style={styles.label}>Teléfono:</label>
                  <input type="text" value={telefono} onChange={handleTelefonoChange} style={styles.input} />
                  <button onClick={guardarTelefono} style={styles.button}><FaSave /></button>
                </div>
              ) : (
                <p><FaPhone /> <strong>Teléfono:</strong> {usuario.datos_cliente ? usuario.datos_cliente.telefono : ''} <button onClick={() => setEditandoTelefono(true)} style={styles.buttonEdit}><FaEdit /></button></p>
              )}
            </div>
          </SeccionDesplegable>
          <SeccionDesplegable titulo="Dirección">
            <div style={styles.contenidoSeccion}>
              {editandoCalle || editandoNumero || editandoColonia || editandoLocalidad ? (
                <>
                  <div>
                    <label style={styles.label}>Calle:</label>
                    <input type="text" value={calle} onChange={handleCalleChange} style={styles.input} />
                  </div>
                  <div>
                    <label style={styles.label}>Número:</label>
                    <input type="text" value={numero} onChange={handleNumeroChange} style={styles.input} />
                  </div>
                  <div>
                    <label style={styles.label}>Colonia:</label>
                    <input type="text" value={colonia} onChange={handleColoniaChange} style={styles.input} />
                  </div>
                  <div>
                    <label style={styles.label}>Localidad:</label>
                    <input type="text" value={localidad} onChange={handleLocalidadChange} style={styles.input} />
                  </div>
                  <button onClick={guardarDireccion} style={styles.button}><FaSave /></button>
                </>
              ) : (
                <>
                  <p><FaMapMarkedAlt /> <strong>Calle:</strong> {usuario.datos_cliente ? usuario.datos_cliente.direccion.calle : ''} <button onClick={() => setEditandoCalle(true)} style={styles.buttonEdit}><FaEdit /></button></p>
                  <p><FaMapMarkedAlt /> <strong>Número:</strong> {usuario.datos_cliente ? usuario.datos_cliente.direccion.numero : ''} <button onClick={() => setEditandoNumero(true)} style={styles.buttonEdit}><FaEdit /></button></p>
                  <p><FaMapMarkedAlt /> <strong>Colonia:</strong> {usuario.datos_cliente ? usuario.datos_cliente.direccion.colonia.nombre : ''} <button onClick={() => setEditandoColonia(true)} style={styles.buttonEdit}><FaEdit /></button></p>
                  <p><FaMapMarkedAlt /> <strong>Localidad:</strong> {usuario.datos_cliente ? usuario.datos_cliente.direccion.colonia.localidad.nombre : ''} <button onClick={() => setEditandoLocalidad(true)} style={styles.buttonEdit}><FaEdit /></button></p>
                </>
              )}
            </div>
          </SeccionDesplegable>
          <SeccionDesplegable titulo="Cambiar Contraseña">
            <div style={styles.contenidoSeccion}>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <FaLock style={{ marginRight: '10px' }} />
                <div style={{ flex: 1 }}>
                  <label style={styles.label}>Contraseña actual:</label>
                  <input
                    type="password"
                    value={contraseñaActual}
                    onChange={handleContraseñaActualChange}
                    style={styles.input}
                  />
                </div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <FaLock style={{ marginRight: '10px' }} />
                <div style={{ flex: 1 }}>
                  <label style={styles.label}>Nueva contraseña:</label>
                  <input
                    type="password"
                    value={nuevaContraseña}
                    onChange={handleNuevaContraseñaChange}
                    style={styles.input}
                  />
                </div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <FaLock style={{ marginRight: '10px' }} /> {/* Cambia este ícono según prefieras */}
                <div style={{ flex: 1 }}>
                  <label style={styles.label}>Confirmar nueva contraseña:</label>
                  <input
                    type="password"
                    value={confirmarNuevaContraseña}
                    onChange={handleConfirmarNuevaContraseñaChange}
                    style={styles.input}
                  />
                </div>
              </div>
              <button onClick={actualizarContraseña} style={styles.buttonActualizar}>
                Actualizar Contraseña
              </button>
            </div>
          </SeccionDesplegable>

        </div>
      )}
    </>
  );

};
const styles = {
  perfilTitle: {
    textAlign: 'center',
  },
  perfilCard: {
    border: '1px solid #ccc',
    borderRadius: '8px',
    padding: '20px',
    margin: '20px auto',
    maxWidth: '600px',
  },
  avatarContainer: {
    textAlign: 'center',
  },
  avatar: {
    width: '100px',
    height: '100px',
    borderRadius: '50%',
  },
  nombreUsuario: {
    margin: '10px 0',
    fontSize: '20px',
  },
  seccion: {
    marginBottom: '20px',
  },
  tituloSeccion: {
    backgroundColor: '#f4f4f4',
    padding: '10px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  contenidoSeccion: {
    border: '1px solid #ddd',
    borderTop: 'none',
    padding: '10px',
  },
  buttonActualizar: {
    backgroundColor: '#ff9800', // Color anaranjado
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    padding: '10px 15px',
    cursor: 'pointer',
    margin: '5px 0',
  },
};
export default PerfilUsuario;